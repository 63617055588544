import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

//Users
export const getAllUsersApi = () => api.get(url.POST_USER);
export const deleteUserApi = (id) => api.delete(`${url.POST_USER}/${id}`);
export const editUserApi = (id, data) =>
  api.update(`${url.POST_USER}/${id}`, data);
export const createUserApi = (data) => api.create(`${url.POST_USER}`, data);
export const getAllCourses = () => api.get(url.POST_CHAPTER);
export const getAllVideoCoursesApi = (data) =>
  api.get(url.GET_VIDEO_COURSES, data);
export const getAllVideoCourseByIdApi = (id) =>
  api.get(`${url.GET_VIDEO_COURSES}/${id}`);
export const createVideoCourseApi = (data) =>
  api.create(url.GET_VIDEO_COURSES, data);
export const createClassesApi = (data) => api.create(url.POST_CLASSES, data);
export const getClassesApi = () => api.get(url.POST_CLASSES);
export const deleteClassByIdApi = (id) =>
  api.delete(`${url.POST_CLASSES}/${id}`);
export const updateClassesApi = (id, data) =>
  api.put(`${url.POST_CLASSES}/${id}`, data);
export const postSetCourse = (data) => api.create(url.POST_SET_COURSE, data);
export const getSetDataApi = (id) => api.get(`${url.GET_SET_DATA}/${id}`);
export const getAllSets = () => api.get(url.POST_SET_COURSE);
export const deleteSetApi = (id) => api.delete(`${url.POST_SET_COURSE}/${id}`);
export const getSet = (id) => api.get(`${url.POST_SET_COURSE}/${id}`);
export const postSet = (id, data) =>
  api.create(`${url.POST_SET_COURSE}/${id}`, data);
export const getAllChapterSets = (data) => api.get(url.POST_CHAPTER_SET, data);
export const postLoginUser = (data) => api.create(url.POST_LOGIN, data);
export const postRegisterUser = (data) => api.create(url.POST_REGISTER, data);
export const postLogout = () => api.create(url.POST_LOGOUT);
export const postCheckTransaction = (data) =>
  api.create(url.POST_TRANSACTION, data);
export const postInitiateTransaction = (data) =>
  api.create(url.POST_INITIATE_TRANSACTION, data);
export const postCheckOtp = (data) => api.create(url.POST_CHECK_OTP, data);
export const postResendOtp = (data) => api.create(url.POST_RESENT_OTP, data);
export const postChapterSetCourse = (data) =>
  api.create(url.POST_CHAPTER_SET, data);
export const postChapterModule = (data) =>
  api.create(url.POST_CHAPTER_MODULE, data);
export const getChapterModuleSetApi = (id) =>
  api.get(`${url.POST_CHAPTER_SET}/${id}`);
export const getSubmissionApi = (id) => api.get(`${url.POST_SUBMISSION}/${id}`);

export const postBlogsApi = (data) => api.create(`${url.BLOGS}`, data);
export const getBlogsApi = () => api.get(`${url.BLOGS}`);
export const getBlogsByIdApi = (id) => api.get(`${url.BLOGS}/${id}`);

///Billings
export const postBillingsApi = (data) => api.create(`${url.BILLINGS}`, data);
export const updateBillingsApi = (id, data) =>
  api.put(`${url.BILLINGS}/${id}`, data);
export const getBillingsApi = () => api.get(`${url.BILLINGS}`);
export const getBillingsByIdApi = (id) => api.get(`${url.BILLINGS}/${id}`);
export const deleteBillingsApi = (id) => api.delete(`${url.BILLINGS}/${id}`);
