import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormFeedback,
  Input,
  Button,
  Alert,
} from "reactstrap";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import Signup from "../../assets/images/signup.svg";
import ParticlesAuth from "./ParticlesAuth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../../slices/User/thunk";
//import images
// import logoLight from "../../../assets/images/logo-light.svg";
const Register = () => {
  document.title = "Basic SignUp | Sikaune";
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      name: "",
      mobileNumber: "",
      college: "",
      faculty: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email")
        .email("Please include an @ in the email address"),
      name: Yup.string().required("Please Enter Your name"),
      mobileNumber: Yup.string()
        .min(10, "Mobile Number must be at least 10 characters")
        .required("Please Enter Mobile Number"),
      college: Yup.string().required("Please Enter Your College"),
      faculty: Yup.string().required("Please Enter Your Faculty"),
    }),
    onSubmit: async (values) => {
      const value = await dispatch(registerUser(values));

      if (value.payload.status === "success") {
        localStorage.setItem("mobileNumber", value.payload.mobileNumber);
        navigate("/check-otp");
      } else {
        setMessage(value.payload.data);
      }
    },
  });
  return (
    <React.Fragment>
      <ParticlesAuth>
        <section className="Register">
          <div className="Register_Left">
            <img src={Signup} alt="signup" />
          </div>
          <div className="Register_Right">
            {message !== "" && message ? (
              <Alert color="danger"> {message} </Alert>
            ) : null}
            <div className="Register_Right_Top">
              <h1 className="Register_Right_Heading">Register</h1>
              <p className="Register_Right_Paragraph">
                Fill out the information to register
              </p>
            </div>

            <Form
              className="Register_Right_Box"
              u
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              action="#"
            >
              <div className="Register_Right_Box_InputBox">
                <label className="Register_Right_Box_InputBox_Heading">
                  Full name <span className="text-danger">*</span>
                </label>
                <Input
                  type="text"
                  className="Register_Right_Box_InputBox_Input"
                  id="name"
                  placeholder="Enter your full name"
                  name="name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="Register_Right_Box_InputBox">
                <label
                  htmlFor="useremail"
                  className="Register_Right_Box_InputBox_Heading"
                >
                  Email <span className="text-danger">*</span>
                </label>
                <Input
                  type="email"
                  className="Register_Right_Box_InputBox_Input"
                  id="useremail"
                  placeholder="Enter email address"
                  name="email"
                  value={validation.values.email}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  invalid={
                    validation.errors.email && validation.touched.email
                      ? true
                      : false
                  }
                />
                {validation.errors.email && validation.touched.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="Register_Right_Box_InputBox">
                <label
                  className="Register_Right_Box_InputBox_Heading"
                  htmlFor="mobile-number"
                >
                  Mobile Number <span className="text-danger">*</span>
                </label>

                <Input
                  type="number"
                  className="Register_Right_Box_InputBox_Input"
                  placeholder="Enter your mobile number"
                  id="mobile-number"
                  name="mobileNumber"
                  value={validation.values.mobileNumber}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  invalid={
                    validation.errors.mobileNumber &&
                    validation.touched.mobileNumber
                      ? true
                      : false
                  }
                />
                {validation.errors.mobileNumber &&
                validation.touched.mobileNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.mobileNumber}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="Register_Right_Box_InputBox">
                <label
                  htmlFor="college"
                  className="Register_Right_Box_InputBox_Heading"
                >
                  College <span className="text-danger">*</span>
                </label>
                <Input
                  type="text"
                  className="Register_Right_Box_InputBox_Input"
                  id="college"
                  placeholder="Enter your college"
                  name="college"
                  value={validation.values.college}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  invalid={
                    validation.errors.college && validation.touched.college
                      ? true
                      : false
                  }
                />
                {validation.errors.college && validation.touched.college ? (
                  <FormFeedback type="invalid">
                    {validation.errors.college}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="Register_Right_Box_InputBox">
                <label
                  htmlFor="faculty"
                  className="Register_Right_Box_InputBox_Heading"
                >
                  Faculty <span className="text-danger">*</span>
                </label>
                <Input
                  type="text"
                  className="Register_Right_Box_InputBox_Input"
                  id="faculty"
                  placeholder="Enter faculty"
                  name="faculty"
                  value={validation.values.faculty}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  invalid={
                    validation.errors.faculty && validation.touched.faculty
                      ? true
                      : false
                  }
                />
                {validation.errors.faculty && validation.touched.faculty ? (
                  <FormFeedback type="invalid">
                    {validation.errors.faculty}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="Register_Right_Box_Button">
                <button className="Register_Right_Box_Button_Button">
                  Register
                </button>
              </div>
            </Form>

            <p className="Register_Right_Paragraph">
              By creating an account, you agree to the Terms of Service and
              Privacy Policy{" "}
            </p>
            <p className="Register_Right_Login">
              Already have an account?{" "}
              <Link to="/login" className="Register_Right_Login_Link">
                {" "}
                Login{" "}
              </Link>{" "}
            </p>
          </div>
        </section>
        {/* <div
          className="auth-page-content section hero-section"
          // style={{ paddingTop: "450px" }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Create New Account</h5>
                      <p className="text-muted">Get your account now</p>
                    </div>
                    {message !== "" && message ? (
                      <Alert color="danger"> {message} </Alert>
                    ) : null}
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        className="needs-validation"
                        action="#"
                      >
                       

                        
                      
                      

                        <div className="mt-4">
                          <button
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Sign Up
                          </button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Already have an account?{" "}
                    <Link
                      to="/login"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      {" "}
                      Signin{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Register;
