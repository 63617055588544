import React from "react";
import withRouter from "../../Components/Common/withRouter";
import Navbar from "../../Components/Navbar";

const ParticlesAuth = ({ children }) => {
  return (
    <React.Fragment>
      {/* <div className="auth-page-wrapper pt-5">
        <div
          className="auth-one-bg-position d-flex align-items-center justify-content-center"
          id="auth-particles"
        > */}
      <Navbar />

      {children}
      {/* </div> */}
      {/* </div> */}
    </React.Fragment>
  );
};

export default withRouter(ParticlesAuth);
