import React, {  useState } from "react";


const CustomTable1 = ({
  data,
  onClick,
  columns,
  itemsPerPage = 10,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginatedData = data.slice(startIndex, endIndex);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const visiblePages = 5;
    const pageNumbers = Array.from(
      { length: totalPages },
      (_, index) => index + 1
    );

    if (totalPages <= visiblePages) {
      return pageNumbers;
    }

    const halfVisiblePages = Math.floor(visiblePages / 2);
    const startPage = Math.max(1, currentPage - halfVisiblePages);
    const endPage = Math.min(totalPages, startPage + visiblePages - 1);

    const pagesToShow = pageNumbers.slice(startPage - 1, endPage);

    return pagesToShow;
  };

  return (
    <div className="todo-task" id="todo-task">
      <div className="table-responsive">
        <table className="table align-middle table-hover position-relative table-nowrap">
          <thead className="table-active">
            <tr>
              {columns?.map((column, index) => (
                <th key={index} scope="col" style={{ width: column.width }}>
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {(paginatedData.length === 0 || paginatedData === null || typeof paginatedData === 'string') && <tr>
              <p>No any data to show</p>
              </tr>}
            {paginatedData !== null && typeof paginatedData !== 'string' && paginatedData.length > 0 && paginatedData?.map((item, key) => (
              <tr
                key={key}
                onClick={() => {
                  onClick && onClick(item);
                }}
              >
                {columns.map((column, colIndex) => (
                  <td key={colIndex} className={column.className}>
                    {column.render ? column.render(item) : item[column.field]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        <nav>
          <ul className="pagination justify-content-end">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
            </li>

            {currentPage > 3 && (
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            )}

            {renderPageNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${
                  currentPage === pageNumber ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            ))}

            {currentPage < totalPages - 2 && (
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            )}

            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default CustomTable1;
