import { createAsyncThunk } from "@reduxjs/toolkit";
import {  getBlogsApi, getBlogsByIdApi, postBlogsApi } from "../../helpers/backend_helper";
export const createBlogs = createAsyncThunk("blogs/createBlogs", async (data) => {
  try {
    const response = await postBlogsApi(data);

    return response;
  } catch (error) {
    return error;
  }
});

export const getBlogs = createAsyncThunk("blogs/getBlogs", async () => {
    try {
      const response = await getBlogsApi()
      return response;
    } catch (error) {
      return error;
    }
});

export const getBlogsById = createAsyncThunk("blogs/getBlogsById", async (id) => {
    try {
      const response = await getBlogsByIdApi(id)
      return response;
    } catch (error) {
      return error;
    }
});