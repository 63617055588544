import { createSlice } from "@reduxjs/toolkit";
import {
  checkTransaction,
  getAllUsers,
  getCourse,
  getSetCourse,
} from "./thunk";

const initialState = {
  user: null,
  loading: false,
  success: false,
  error: false,
  errorMessage: "",
  users: [],
};

export const userSlice = createSlice({
  name: "setCourse",
  initialState,
  reducers: {
    selectSetCourse: (state, action) => {
      state.selectedSet = state.sets.filter(
        (set) => set._id === action.payload
      )[0];
    },
    deleteUserReducer: (state,action) =>{
      state.users = state.users.filter(user=> user._id !== action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(checkTransaction.fulfilled, (state, action) => {});
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.users = action.payload.data.data;
    });
   
    // builder.addCase(getSetCourse.fulfilled, (state, action) => {
    //   state.sets = action.payload.data;
    //   // state.courses = action.payload.data.data;
    // });
  },
});

// Action creators are generated for each case reducer function
export const { selectSetCourse, deleteUserReducer } = userSlice.actions;

export default userSlice.reducer;
