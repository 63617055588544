import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import Navbar from "../Components/Navbar";
import { useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
const AdminProtected = (props) => {
  const token = localStorage.getItem("token");
  const userProfile = JSON.parse(localStorage.getItem("user"));
  if (!token || !userProfile) {
    return <Navigate to="/login" />;
  }
  if (userProfile.admin === false) {
    return <Navigate to="/set-wise" />;
  }
  setAuthorization(token, userProfile?.deviceId);
  // const notify = () =>
  //   toast.success("Set Course Added Successfully", {
  //     style: {
  //       backgroundColor: "#28a745" /* Custom green color */,
  //       color: "white" /* Adjust text color for visibility */,
  //     },
  //     icon: false /* Optionally, you can remove the default icon */,
  //   });
  // const [selectedFiles, setselectedFiles] = useState([]);
  // const [files, setFiles] = useState([]);
  // const [message, setMessage] = useState("");
  // const dispatch = useDispatch();
  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    // setselectedFiles(files);
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();

    // formData.append("file", selectedFiles[0]);
    // const response = await dispatch(addSetCourse(formData));
    // if (response.payload.status === "success") {
    //   setselectedFiles([]);
    //   notify();
    // }
  };
  return (
    <React.Fragment>
      <div className="AdminDashboard">
        <Navbar />
        <section className="AdminDashboard_Section">
          <div className="AdminDashboard_Nav">
            <Link to="/admin/set-course" className="AdminDashboard_Nav_Link">
              <p className="AdminDashboard_Nav_Link_Paragraph">Mock test</p>
            </Link>
            <Link to='/admin/classes' className="AdminDashboard_Nav_Link">
              <p className="AdminDashboard_Nav_Link_Paragraph">Live Classes</p>
            </Link>
            <Link to='/admin/chapter' className="AdminDashboard_Nav_Link">
              <p className="AdminDashboard_Nav_Link_Paragraph">Add Chapter</p>
            </Link>
            <Link to='/admin/courses' className="AdminDashboard_Nav_Link">
              <p className="AdminDashboard_Nav_Link_Paragraph">
                Recorded Class
              </p>
            </Link>

            <Link to='/admin/chapter-wise' className="AdminDashboard_Nav_Link">
              <p className="AdminDashboard_Nav_Link_Paragraph">MCQs</p>
            </Link>
            <Link to='/admin/billings' className="AdminDashboard_Nav_Link">
              <p className="AdminDashboard_Nav_Link_Paragraph">Billings</p>
            </Link>
            <Link to='/admin/users' className="AdminDashboard_Nav_Link">
              <p className="AdminDashboard_Nav_Link_Paragraph">Students</p>
            </Link>
            <Link to='/admin/blogs' className="AdminDashboard_Nav_Link">
              <p className="AdminDashboard_Nav_Link_Paragraph">
                Blogs
              </p>
            </Link>
          </div>
          <main className="AdminDashboard_Main">
            <p className="AdminDashboard_Main_Paragraph">Good Morning, Admin</p>
            {props.children}
          </main>
        </section>
      </div>
    </React.Fragment>
  );
};

export default AdminProtected;
