import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useDispatch } from "react-redux";
import { checkTransaction } from "../../slices/User/thunk";
import { setAuthorization } from "../../helpers/api_helper";

const Index = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const transactionId = searchParams.get("transaction_id");
  const subscriptionId = searchParams.get("subscriptionId");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const deviceId = localStorage.getItem("user");
  const device = JSON.parse(deviceId);
  setAuthorization(token, device.deviceId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (transactionId) {
          const response = await dispatch(
            checkTransaction({
              transactionId: transactionId,
              subscriptionId: subscriptionId.split("?")[0],
            })
          );
          const user = localStorage.getItem("user");
          
          const userProfile = JSON.parse(user);
          // const user = JSON.parse(localStorage.getItem('user'));
          
          userProfile.payment = true;
          localStorage.setItem("user", JSON.stringify(userProfile));

          
          if (response.payload.data === "This transaction is already done") {
            setError(true);
          }
        }
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    };

    fetchData();
  }, [dispatch, transactionId]);
  return (
    <div>
      <Navbar />
      <section
        className="section pb-0 hero-section"
        id="hero"
        style={{ height: "80vh" }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4 text-center">
                  <div className="avatar-lg mx-auto mt-2">
                    {!error ? (
                      <div className="avatar-title bg-light text-success display-3 rounded-circle">
                        <i className="ri-checkbox-circle-fill"></i>
                      </div>
                    ) : (
                      <div className="avatar-title bg-light text-danger display-3 rounded-circle">
                        <i class="ri-close-circle-fill"></i>
                      </div>
                    )}
                  </div>
                  <div className="mt-4 pt-2">
                    {!error ? (
                      <h4>Payment Successful</h4>
                    ) : (
                      <h4>Payment Unsuccessful</h4>
                    )}

                    <p className="text-muted mx-4">
                      {!error ? (
                        <h4>
                          {" "}
                          Aww yeah, Khalti payment has been done successfully
                        </h4>
                      ) : (
                        <h4>Payment already done with that id</h4>
                      )}
                    </p>
                    <div className="mt-4">
                      <Link to="/set-wise" className="btn btn-success w-100">
                        Back to Home
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default Index;
