import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card, CardBody, Button } from "reactstrap";

import Question from "./Questions";
import Timer from "./Timer";
import { useSelector, useDispatch } from "react-redux";
import QuestionSubmit from "./QuestionSubmit";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { postChapterSet } from "../../slices/SetCourse/thunk";
import { isSellected } from "../../utils/GetChecked";
import { hasPaid } from "../../utils/hasPaid";

const Home = () => {
  const [provideAnswer, setProvideAnswer] = React.useState([]);
  const [totalCorrectAnswer, setTotalCorrectAnswer] = React.useState(0);
  const setQuestion = useSelector((state) => state.set);
  const navigate = useNavigate();
  const { selectedSet, data, providedAnswer } = setQuestion;
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const [searchParams] = useSearchParams();
  const setDataId = searchParams.get("setDataId");
  useEffect(() => {
    const stickyCol = document.querySelector(".sticky-col");

    if (stickyCol) {
      const section = document.querySelector(".hero-section");
      const stickyOffsetTop = section.offsetTop; // Get the top offset of the section

      const handleScroll = () => {
        if (window.pageYOffset > stickyOffsetTop) {
          stickyCol.style.position = "sticky";
          stickyCol.style.top = "0";
          // stickyCol.style.width = "25%"; // Adjust the width as needed
        } else {
          stickyCol.style.position = "static";
          stickyCol.style.width = "auto";
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);
  const onSubmit = (event) => {
    event.preventDefault();

    const postData = {
      isSet: true,
      totalCorrectAnswer: totalCorrectAnswer,
      totalQuestion: data.length,
      userId: user._id,
      providedAnswer: JSON.stringify(provideAnswer),
    };

    const response = dispatch(postChapterSet({ id, postData: postData }));
    if (response) {
      navigate("/submission");
    }
  };

  const handleSelect = (selectionOption, index, selectedAnswer) => {
    const answer = {
      index,
      selectionOption,
      selectedAnswer,
    };

    const isChooseOption = provideAnswer.find((el) => el.index === index);
    const isSelectedOptionSame = provideAnswer.find(
      (el) => el.index === index && el.selectionOption === selectionOption
    );

    if (!isChooseOption) {
      if (data[index].selectedAnswer === selectedAnswer) {
        setTotalCorrectAnswer(() => totalCorrectAnswer + 1);
      }
      return setProvideAnswer((prev) => [...prev, answer]);
    }

    const updateProvideAnswer = [...provideAnswer];

    let optionPosition;

    provideAnswer.map((el, i) => {
      if (el.index === index) optionPosition = i;
    });

    if (isChooseOption && !isSelectedOptionSame) {
      updateProvideAnswer.splice(optionPosition, 1, answer);

      if (data[index].selectedAnswer === selectedAnswer) {
        setTotalCorrectAnswer(() => totalCorrectAnswer + 1);
      } else {
        setTotalCorrectAnswer(() => totalCorrectAnswer - 1);
      }
    } else {
      updateProvideAnswer.splice(optionPosition, 1);
    }

    setProvideAnswer(updateProvideAnswer);
  };
  const checkAnswer = (index) => {
    if (providedAnswer !== null) {
      const provideAnswer = JSON.parse(providedAnswer?.providedAnswer);

      const isChooseOption = provideAnswer.find((el) => el.index === index);

      if (isChooseOption?.selectedAnswer === data[index].selectedAnswer) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <React.Fragment>
      <section className="Landing1">
        <div className="Landing1_Content">
          <div className="Landing1_Left">
            {/* <div className="Landing_Left_Top"> */}
            {providedAnswer === null && <Timer />}
            {/* </div> */}
            <div className="Landing1_Left_Bottom">
              <h1 className="Landing1_Left_Bottom_Heading">
                Question completed
              </h1>
              <div className="Landing1_Left_Bottom_Box">
                {data !== null &&
                  data.length > 0 &&
                  data.map((option, index) => {
                    return (
                      <div
                        className={`Landing1_Left_Bottom_Box_Box ${
                          providedAnswer === null
                            ? isSellected(provideAnswer, index) && "bg-success"
                            : checkAnswer(index)
                            ? "bg-success"
                            : "bg-danger"
                        }`}
                      >
                        {" "}
                        {index + 1}
                      </div>
                      // <button
                      //   className={`col m-1 py-2 px-3 border-0 outline-none rounded ${
                      //     providedAnswer === null
                      //       ? isSellected(provideAnswer, index) && "bg-success"
                      //       : checkAnswer(index)
                      //       ? "bg-success"
                      //       : "bg-danger"
                      //   }

                      //        `}
                      //   key={option.id}
                      // >
                      //   {index + 1}
                      // </button>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="Landing1_Right">
            <h1 className="Landing1_Right_Heading">Choose the right answer</h1>
            {data !== null &&
              data.length > 0 &&
              data.map((dat, index) =>
                providedAnswer === null ? (
                  <Question
                    {...dat}
                    isFinished={true}
                    index={index}
                    handleSelect={handleSelect}
                    provideAnswer={provideAnswer}
                    answerGiven={providedAnswer}
                  />
                ) : (
                  <QuestionSubmit
                    {...dat}
                    isFinished={true}
                    index={index}
                    handleSelect={handleSelect}
                    provideAnswer={provideAnswer}
                    answerGiven={providedAnswer}
                    showDescription={dat.showDescription}
                  />
                )
              )}
            {!setDataId && (
              <div className="Landing1_Right_Button">
                <button
                  className="Landing1_Right_Button_Primary"
                  onClick={onSubmit}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
      {/* <section className="section pb-0 hero-section mt-4" id="hero" style={{minHeight: '80vh'}}>
        <Container>
          {providedAnswer !== null && (
            <Row className="justify-content-center">
              <Col lg={12} sm={12}>
                <Card className="p-4">
                  <h3 style={{ color: "black" }}>Quiz Results</h3>
                  <p className="text-muted">
                    {providedAnswer.totalCorrectAnswer} of {data.length} correct
                  </p>
                </Card>
              </Col>
            </Row>
          )}
          <Row className="justify-content-between" style={{background: 'white'}}>
            <Col lg={6} sm={6} className="align-items-start">
              {providedAnswer === null && <Timer />}
            </Col>
            <Col lg={6} sm={6}>
              {!setDataId && (
                <div
                  className="position-sticky bottom-0 start-0 w-full d-flex align-items-center justify-content-end"
                  style={{ background: "white", height: "70px" }}
                >
                  <Button className="btn-success" onClick={onSubmit}>
                    Submit
                  </Button>
                </div>
              )}
            </Col>
          </Row>
          <Row className="justify-content-center position-relative">
            <Col lg={4} sm={12} className="position-sticky top-0 start-0">
              <Card>
                <CardBody
                  className="flex-wrap justify-content-between"
                  style={{ display: "flex" }}
                >
                  <div className="row justify-content-between">
                    {data !== null &&
                      data.length > 0 &&
                      data.map((option, index) => {
                        return (
                          <button
                            className={`col m-1 py-2 px-3 border-0 outline-none rounded ${
                              providedAnswer === null
                                ? isSellected(provideAnswer, index) &&
                                  "bg-success"
                                : checkAnswer(index)
                                ? "bg-success"
                                : "bg-danger"
                            }          
                    
                             `}
                            key={option.id}
                          >
                            {index + 1}
                          </button>
                        );
                      })}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col
              lg={8}
              sm={12}
              style={{ maxHeight: `calc(100vh - 90px)`, overflow: "scroll" }}
              className="position-relative"
            >
              {data !== null &&
                data.length > 0 &&
                data.map((dat, index) =>
                  providedAnswer === null ? (
                    <Question
                      {...dat}
                      isFinished={true}
                      index={index}
                      handleSelect={handleSelect}
                      provideAnswer={provideAnswer}
                      answerGiven={providedAnswer}
                    />
                  ) : (
                    <QuestionSubmit
                      {...dat}
                      isFinished={true}
                      index={index}
                      handleSelect={handleSelect}
                      provideAnswer={provideAnswer}
                      answerGiven={providedAnswer}
                      showDescription={dat.showDescription}
                    />
                  )
                )}
            </Col>
          </Row>
        </Container>
      </section> */}
    </React.Fragment>
  );
};

export default Home;
