import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import CustomTable1 from "../../../Components/Common/CustomTable1";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  deleteClassById,
  getClasses,
  updateClassById,
} from "../../../slices/Courses/thunk";

const AdminClassesDetails = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getClasses());
  }, [dispatch]);
  const course = useSelector((state) => state.course);

  const { classes } = course;
  const [open, setOpen] = useState("");

  const [data, setData] = useState();
  const [className, setClassName] = useState(null);
  const [url, setUrl] = useState(null);

  const toggle = () => setOpen("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data1 = {
      ...data,
      className: className ? className : data.className,
      link: url ? url : data.link,
    };
    // dispatch(updateBillingsById(data1));
    const response = await dispatch(updateClassById(data1));
    if (response.payload.status === "success") {
      toast.success("Classes updated successfully", { autoClose: 1000 });
      toggle();
      window.location.reload();
    }
  };

  const columns = [
    {
      header: "Class Name",
      field: "className",
      width: "auto",
      truncate: true,
      className: "truncated-text",
    },

    {
      header: "Action",
      field: "scoring_percentage",
      width: "12%",
      truncate: false,
      render: (item) => {
        return (
          <div>
            <Modal
              isOpen={open === item?._id}
              toggle={toggle}
              centered
              className="Modal"
            >
              <ModalBody className="Modal_Body">
                <h1 className="AddUser_Heading">Edit Classes Information</h1>

                <div className="AddUser_Input">
                  <p className="AddUser_Input_Name">Class Name</p>
                  <input
                    className="AddUser_Input_Input"
                    value={className === null ? data?.className : className}
                    onChange={(e) => {
                      setClassName(e.target.value);
                    }}
                  />
                </div>
                <div className="AddUser_Input">
                  <p className="AddUser_Input_Name">Class Url</p>
                  <input
                    className="AddUser_Input_Input"
                    value={url === null ? data?.link : url}
                    onChange={(e) => {
                      setUrl(e.target.value);
                    }}
                  />
                </div>

                <button className="AddUser_Button" onClick={handleSubmit}>
                  Edit Classes
                </button>
              </ModalBody>
            </Modal>
            <Button
              className="btn-primary"
              onClick={() => {
                setOpen(item?._id);
                setData(item);
              }}
            >
              Edit
            </Button>
            <Button
              className="btn-danger"
              onClick={async (e) => {
                // e.preventDefault();
                // dispatch(deleteUserReducer(item?._id));
                // dispatch(deleteUser(item?._id));
                const response = await dispatch(deleteClassById(item._id));
                if (response.payload.status === "success") {
                  toast.success("Classes deleted successfully");
                  window.location.reload();
                }
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="AdminUsers">
        {/* <div className="AdminUsers_Input"> */}
        <input type="text" className="AdminUsers_Input" placeholder="Search" />
        {/* </div> */}
        <button
          className="AdminUsers_Button"
          onClick={() => {
            navigate("/admin/classes/create");
          }}
        >
          Add Classes
        </button>
      </div>
      <CustomTable1
        data={classes || []}
        columns={columns}
        onClick={(item) => {
          // setShowEmployee(item);
          // setBooleanEmployee(true);
        }}
      />
    </React.Fragment>
  );
};

export default AdminClassesDetails;
