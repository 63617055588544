import React from "react";
import AboutImage from "../../assets/images/About.svg";
import AboutImage1 from "../../assets/images/About1.svg";
import AboutLanding from "../../assets/images/AboutLanding.png";
const About = () => {
  return (
    <section className="About">
      <img src={AboutImage} alt="About_Image" className="About_Image" />
      <img src={AboutImage1} alt="About_Image 1" className="About_Image_1" />
      <div className="About_Content">
        <div className="About_Content_Left">
          <h1 className="About_Content_Left_Heading">About Sikaune</h1>
          <p className="About_Content_Left_Paragraph">
            Sikaune is a group of engineers and educators developing online
            learning materials specializing in engineering fraternity. Sikaune
            provides skill development trainings for professional development.
          </p>
        </div>
        <div className="About_Content_Right">
          <img src={AboutLanding} alt="About Landing" />
          <div className="About_Content_Right_Rectangle"></div>
        </div>
      </div>
    </section>
  );
};

export default About;
