import React from "react";
import notFound from "../assets/images/notFound.svg";
const NotFound = ({ text }) => {
  return (
    <div className="NotFound">
      <h1 className="NotFound_Heading">{text}</h1>
      <img src={notFound} alt="sikaune not found" className="NotFound_Image"/>
      <button className="NotFound_Button">Get Started</button>
    </div>
  );
};

export default NotFound;
