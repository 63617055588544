import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [
    {
      id: 1,
      question: "Choose the correct tag for the largest heading in HTML.",
      options: [
        { option: "header", description: "dfghjk" },
        { option: "h1", description: "cvbnm" },
        { option: "heading", description: "gh" },
        { option: "h6", description: "fghjk,hbklg" },
      ],
      selectedOption: "",
      showDescription: null,
    },
    {
      id: 2,
      question: "Choose the correct tag for the small heading in HTML.",
      options: [
        { option: "h3", description: "dfghjk" },
        { option: "h1", description: "cvbnm" },
        { option: "h5", description: "gh" },
        { option: "h6", description: "fghjk,hbklg" },
      ],
      selectedOption: "",
      showDescription: null,
    },
  ],
};

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    changeOption: (state, action) => {
      const { index, subIndex } = action.payload;
      const selectedOption = state.data[index].options[subIndex].option;
      state.data[index].selectedOption = selectedOption;
    },
    changeDescription: (state, action) => {
      const { index, subIndex } = action.payload;
      state.data[index].showDescription = subIndex;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeOption, changeDescription } = questionSlice.actions;

export default questionSlice.reducer;
