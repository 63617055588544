import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
//routes
import {
  adminProtectedRoutes,
  authProtectedRoutes,
  publicRoutes,
} from "./allRoutes";
import NonAuthLayout from "../Layouts/NonAuthLayout";
import AuthProtected from "./AuthProtected";
import AdminProtected from "./AdminProtected";
import ScrollToTop from "./scrollToTop";

const Index = () => {
  return (
    <React.Fragment>
      {" "}
      {/* <ScrollToTop /> */}
      <Routes>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route path={route.path} element={route.component} key={idx} />
          ))}
          {adminProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<AdminProtected>{route.component}</AdminProtected>}
              key={idx}
            />
          ))}
          <Route>
            {authProtectedRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <AuthProtected>
                    <NonAuthLayout>{route.component}</NonAuthLayout>
                  </AuthProtected>
                }
                key={idx}
              />
            ))}
          </Route>
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default Index;
