import React, { useEffect } from "react";
import { isAdmin } from "../../utils/isAdmin";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteClassById, getClasses } from "../../slices/Courses/thunk";
import notFound from "../../assets/images/sad-face.png";
import { deleteClass } from "../../slices/Courses/reducer";
import { hasPaid } from "../../utils/hasPaid";
import { setAuthorization } from "../../helpers/api_helper";
import Navbar1 from "../../Components/Navbar1";
import Footer1 from "../../Components/Footer1";
import NotFound from "../../Components/NotFound";
const Modules = ({ data }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onDelete = () => {
    dispatch(deleteClassById(data._id));
    dispatch(deleteClass(data._id));
  };
  return (
    <div
      className="d-flex flex-column w-full mt-4 p-3 rounded"
      style={{ height: "auto", background: "white" }}
    >
      <div className="d-flex align-items-center w-full">
        <div className="" style={{ flex: 1, height: "auto" }}>
          {data.className}
        </div>
        <div className="d-flex" style={{ height: "auto", width: "auto" }}>
          <button
            className="btn btn-success"
            style={{ marginRight: "15px" }}
            // onClick={() => onClick(data.link)}
          >
            Join Classroom
          </button>
          {/* {isAdmin() && (
            <button className="btn btn-danger" onClick={() => onDelete()}>
              Delete classroom
            </button>
          )} */}
        </div>
      </div>
    </div>
  );
};
const Index = () => {
  document.title = "Classes | Sikaune";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {

    dispatch(getClasses());
  }, [dispatch]);
  const course = useSelector((state) => state.course);

  const { classStatus, classes } = course;

 
 
  const onClick = (link) => {

    if (hasPaid()) {
      window.open(link, "_blank");
    } else {
      navigate("/buy-now");
    }
  };
  return (
    <React.Fragment>
      <div className="layout-wrapper landing">
        <Navbar />
        <section className="Classes">
          <div className="Classes_Box">
            <div className="Classes_Box_Flex">
              <h1 className="Classes_Box_Heading">Your Classes</h1>
              {/* {isAdmin() && (
                <button
                  className="Classes_Box_Content_Single_Button"
                  onClick={() => navigatedTo()}
                >
                  Create New Class
                </button>
              )} */}
            </div>
            <div className="Classes_Box_Content">
              {classes.length > 0 &&
                typeof classes !== "string" &&
                classes?.map((singleClass, index) => {
                  return (
                    <div className="Classes_Box_Content_Single">
                      <p className="Classes_Box_Content_Single_paragraph">
                        Civil Engineering
                      </p>
                      <h1 className="Classes_Box_Content_Single_heading">
                        {singleClass.className}
                      </h1>
                      <p className="Classes_Box_Content_Single_paragraph1">
                        This course covers all the basics and you will be
                        provided with model sets and course videos
                      </p>
                      <button
                        className="Classes_Box_Content_Single_Button"
                        onClick={() => onClick(singleClass.link)}
                      >
                        Join Now
                      </button>
                    </div>
                  );
                })}
          
                {typeof classes === "string" &&(
                  <NotFound text="Sorry, we don’t have any classes available for you." />

                )}
              
            </div>
          </div>
        </section>
        {/* <section
          className="section pb-0 hero-section"
          id="hero"
          // style={{ minHeight: "80vh" }}
        >
          <Container>
            <Row className="justify-content-between">
              <Col sm={6} md={6} >
                <h4 className="text-black">
                  Classes
                </h4>
              </Col>
              <Col sm={6} md={6} className="d-flex justify-content-end">
                {isAdmin() && (
                  <button
                    className="btn btn-success"
            
                    onClick={() => navigatedTo()}
                  >
                    Create New Class
                  </button>
                )}
              </Col>
            </Row>

            {classStatus === "completed" &&
              (classes.length === 0 || typeof classes === "string") && (
                <div className="d-flex flex-column justify-content-center align-items-center h-full">
                  <img
                    src={notFound}
                    alt="Not found "
                    className="w-auto"
                    style={{ height: "350px" }}
                  />
                  <h4 className="text-black mt-4 mb-4">
                    Sorry, we could not find any classes
                  </h4>
                </div>
              )}
            {classes.length > 0 &&
              typeof classes !== "string" &&
              classes?.map((singleClass, index) => {
                return <Modules data={singleClass} />;
              })}
             
          </Container>
        </section> */}
        {/* <Footer /> */}
        <Footer1 />

        <button
          onClick={() => toTop()}
          className="btn btn-danger btn-icon landing-back-top"
          id="back-to-top"
        >
          <i className="ri-arrow-up-line"></i>
        </button>
      </div>
    </React.Fragment>
  );
};

export default Index;
