import React, { useEffect } from "react";

import Navbar from "../../Components/Navbar";
import Home from "./Home";
import Footer from "../../Components/Footer";
import { useDispatch } from "react-redux";
import { getBlogs } from "../../slices/Blogs/thunk";

const Blogs = () => {
  document.title = " Blogs";
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getBlogs());
  },[]);
  
  return (
    <React.Fragment>
      <div className="layout-wrapper landing">
        <Navbar />
        <Home />
        <Footer/>

        <button
          className="btn btn-danger btn-icon landing-back-top"
          id="back-to-top"
        >
          <i className="ri-arrow-up-line"></i>
        </button>
      </div>
    </React.Fragment>
  );
};

export default Blogs;
