export const isChecked = (option, provideAnswer, index) => {
  const status = provideAnswer.find(
    (el) => el.selectionOption === option && el.index === index
  );

  return status;
};

export const isSellected = (provideAnswer, index) => {
  const status = provideAnswer.find((el) => el.index === index);

  return status;
};
