import React, { useState } from "react";
import Navbar from "../../../Components/Navbar";
import { Col, Row, Card, CardHeader, CardBody } from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postChapterModule } from "../../../helpers/backend_helper";
import { createBlogs } from "../../../slices/Blogs/thunk";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const AdminBlogs = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [blogBody, setBlogBody] = useState("");
  const notify = () =>
    toast.success("Blogs Added Successfully", {
      style: {
        backgroundColor: "#28a745" /* Custom green color */,
        color: "white" /* Adjust text color for visibility */,
      },
      icon: false /* Optionally, you can remove the default icon */,
    });
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(JSON.stringify(blogBody));
    // console.log(blogBody)
    let formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("blogBody", blogBody);
    // const data = {
    //   title: title,
    //   description: description,
    //   blogBody: blogBody
    // }
    const response = await dispatch(createBlogs(formData));
    if (response.payload.status === "success") {
      notify();
    }
  };

  return (
    <React.Fragment>
      <div className="AdminDashboard_Main_Box">
        <div>
          <h1 className="AdminDashboard_Main_Box_Heading">Add Blog</h1>
          {/* <p className="AdminDashboard_Main_Box_Paragraph">New Billing</p> */}
        </div>
        <input
          className="AdminDashboard_Main_Box_Right_Select"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Blog Title"
        />
        <textarea
          className="AdminDashboard_Main_Box_Right_Textarea"
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Blog Description"
        />
        <CKEditor
          className="AdminDashboard_Main_Box_Right_Textarea"
          editor={ClassicEditor}

          onChange={(event, editor) => {
            const data = editor.getData();
            console.log({ data });
            setBlogBody(data);
          }}
          // onBlur={(event, editor) => {
          //   console.log("Blur.", editor);
          // }}
          // onFocus={(event, editor) => {
          //   console.log("Focus.", editor);
          // }}
        />
        <button
          className="AdminDashboard_Main_Box_Right_Button"
          onClick={handleSubmit}
        >
          Add Blog
        </button>
      </div>{" "}
    </React.Fragment>
  );
};

export default AdminBlogs;
