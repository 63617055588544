import React from 'react'

const Moments = () => {
  return (
    <section className="Moments">
      <div className="Moments_Content">
        <div>
          <p className="Moments_Content_Paragraph">Photo Gallery</p>
          <h1 className="Moments_Content_Heading">
            Moments captured at Sikaune
          </h1>
        </div>
        <div className="Moments_Content_Box">
          <div className="Moments_Content_Box_firstrow">
            <div className="firstrow1">
              <img src="https://utfs.io/f/d926c4d9-d565-4ac3-ae8a-108335bb8e1a-ny8zrl.png" alt="" />
            </div>
            <div className="firstrow2">
              <img src="https://utfs.io/f/523a61be-9901-4c46-84b7-518f8f013da7-ny8zrj.png" alt="" />
              <iframe width="469" height="249" src="https://www.youtube.com/embed/rZsMIHhbdkw?si=7S1aFdXKKgwDE_0p" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className="firstrow3">
              <a href="https://www.facebook.com/reel/409109414842283" target='_blank' rel="noreferrer">
                <img src="https://utfs.io/f/1959723d-7cda-46dc-88f0-e3ad8b2ea1ef-bdtbz3.png" alt="" />
              </a>

            </div>
          </div>
          <div className="Moments_Content_Box_secondRow">
            <div>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/yYfPdfszIuw?si=TN8G4OsfBABKtWEw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>

            <div>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/8cg4ULn7Is0?si=AxHZArYwxMeUouSy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div>


      </div>


    </section>
  )
}

export default Moments