import { createSlice } from "@reduxjs/toolkit";
import { createBlogs, getBlogs, getBlogsById, getCourse } from "./thunk";

const initialState = {
  blogs: [],
  selectedBlog: null
};

export const blogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    selectCourse: (state, action) => {
      state.selectedCourse = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(createBlogs, (state, action) => {
    // //   state.cour = action.payload.data;
    // });
    builder.addCase(getBlogs.fulfilled, (state, action) => {
        
        state.blogs = action.payload.data;
      });
      builder.addCase(getBlogsById.fulfilled, (state,action)=>{
        state.selectedBlog = action.payload.data;
      })
  },
});

// Action creators are generated for each case reducer function
export const { selectCourse } = blogsSlice.actions;

export default blogsSlice.reducer;
