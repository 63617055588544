import React, { useEffect } from "react";

import Navbar from "../../Components/Navbar";
// import Timer from "./Timer";
import Home from "./Home";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getChapterModuleSet,
  getChapterSet,
  getSetData,
} from "../../slices/SetCourse/thunk";
import { useSearchParams } from "react-router-dom";
import { clearSetData } from "../../slices/SetCourse/reducer";
import Footer from "../../Components/Footer";
const Index = () => {
  document.title = "Questions | Sikaune";
  const { id } = useParams();
  const dispatch = useDispatch();
  window.onscroll = function () {
    scrollFunction();
  };
  const [searchParams] = useSearchParams();
  const setDataId = searchParams.get("setDataId");

  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  useEffect(() => {
    // dispatch(getChapterSet(id));
    dispatch(getChapterModuleSet(id));
    
    if (setDataId) {
      
      dispatch(getSetData(setDataId));
    }
    return () =>{
      dispatch(clearSetData());
    }
  }, [id, dispatch]);
  return (
    <React.Fragment>
      <div className="layout-wrapper landing">
        <Navbar />
        <Home />
        {/* <Footer/> */}

        <button
          onClick={() => toTop()}
          className="btn btn-danger btn-icon landing-back-top"
          id="back-to-top"
        >
          <i className="ri-arrow-up-line"></i>
        </button>
      </div>
    </React.Fragment>
  );
};

export default Index;
