import React from "react";

const GoFar = () => {
  return (
    <section className="GoFar">
      <div className="GoFar_Content">
        <h1 className="GoFar_Content_Heading">
          Want To Go Far? Try{" "}
          <span className="GoFar_Content_Span">Sikaune</span>
        </h1>
        <div className="GoFar_Content_Box">
          <div className="GoFar_Content_Single">
            <p className="GoFar_Content_Single_Paragraph">
              Entrance Preparation courses for +2 students.
            </p>
          </div>
          <div className="GoFar_Content_Single">
            <p className="GoFar_Content_Single_Paragraph">
              NEC License Exam Preparations
            </p>
          </div>
          <div className="GoFar_Content_Single">
            <p className="GoFar_Content_Single_Paragraph">
              Development Training Programs
            </p>
          </div>
          <div className="GoFar_Content_Single">
            <p className="GoFar_Content_Single_Paragraph">
              20+ Courses with Live and Recorded Classes
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GoFar;
