import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getChapterSets } from "../../slices/SetCourse/thunk";
import NEC_LOGO from "../../assets/images/NEC_logo.gif";
import notFound from "../../assets/images/sad-face.png";
import { hasPaid } from "../../utils/hasPaid";
import { isAdmin } from "../../utils/isAdmin";
import NotFound from "../../Components/NotFound";
const Course = ({ set }) => {
  const noOfQuestion = JSON.parse(set.questionSets).length;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setQuestion = () => {
    if (hasPaid()) {
      navigate(`/chapter-wise/${set._id}`);
    } else {
      navigate("/buy-now");
    }
  };
  return (
    <Col key={1} lg={4} md={6} sm={12}>
      <Card className="explore-box card-animate"  style={{ border: "1px solid var(----gray, #E6E6E6)" }}>
        <div className="explore-place-bid-img">
          <input type="hidden" className="form-control" id="1" />
          <div className="d-none">undefined</div>
          <img src={NEC_LOGO} alt="" className="card-img-top explore-img" />
          <div className="bg-overlay"></div>
          <div className="place-bid-btn">
            <button
              className="btn btn-primary TestSet_Box_Content_Single_Button"
              onClick={setQuestion}
            >
              <i className="ri-auction-fill align-bottom me-1"></i>View Chapter
            </button>
          </div>
        </div>
        <CardBody>
          <h5 className="TestSet_Box_Content_Single_Heading">
            <Link
              to="/course"
              //   to={course.course_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              NEC license exam preparation
            </Link>
          </h5>
          <p className="TestSet_Box_Content_Single_paragraph">
            Civil Engineering
          </p>
          <p className="TestSet_Box_Content_Single_paragraph">
            {noOfQuestion} Questions
          </p>
        </CardBody>
      </Card>
    </Col>
  );
};
const Home = () => {
  let { chapterId, subModuleId } = useParams();
  const dispatch = useDispatch();
  const set = useSelector((state) => state.set);
  const navigate = useNavigate();
  const { chapterSets, chapterSetStatus } = set;
  useEffect(() => {
    dispatch(
      getChapterSets({ chapterId: chapterId, subModuleId: subModuleId })
    );
  }, [dispatch, chapterId, subModuleId]);

  return (
    <React.Fragment>
      <section className="TestSet">
        <div className="TestSet_Box">
          <div className="Classes_Box_Flex">
            <h1 className="TestSet_Box_Heading">Mock-Test Set</h1>
            {/* {isAdmin() && (
              <button
                className="Classes_Box_Content_Single_Button"
                onClick={() =>
                  navigate(`/admin/chapter-wise/${chapterId}/${subModuleId}`)
                }
              >
                Upload set questions
              </button>
            )} */}
          </div>
          <div className="TestSet_Box_Content">
          {(!chapterSets ||
                chapterSets.length === 0 ||
                typeof chapterSets === "string") &&
                chapterSetStatus === "completed" && (
                  <NotFound text="Sorry, we don’t have any mock tests available for this subject." />
                )}
            <Row className="m-0">
              {chapterSets?.length > 0 &&
                chapterSets.map((set) => <Course set={set} />)}
              
            </Row>
          </div>
        </div>
      </section>
      {/* <section
        className="section pb-0 hero-section mt-4"
        id="hero"
        style={{ minHeight: "80vh" }}
      >
        <Container>
          <Row className="justify-content-center" style={{ marginTop: "30px" }}>
            {chapterSets &&
              typeof chapterSets !== "string" &&
              chapterSets.length > 0 &&
              chapterSets.map((chapterSet, index) => {
                return <Course set={chapterSet} />;
              })}
            {(!chapterSets ||
              chapterSets.length === 0 ||
              typeof chapterSets === "string") &&
              chapterSetStatus === "completed" && (
                <div className="d-flex flex-column justify-content-center align-items-center h-full">
                  <img
                    src={notFound}
                    alt="Not found "
                    className="w-auto"
                    style={{ height: "350px" }}
                  />
                  <h4 className="text-black mt-4 mb-4">
                    Sorry, we could not find any set questions
                  </h4>
                </div>
              )}
          </Row>
        </Container>
      </section> */}
    </React.Fragment>
  );
};

export default Home;
