import React, { useEffect, useState, useLayoutEffect } from "react";
import SikauneLogo from "../assets/images/SikauneLogo.png";
import ProfileDropdown from "./Common/profileDropdown";
import { Link } from "react-router-dom";
import { NavbarToggler } from "reactstrap";
const Navbar = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [navClass, setnavClass] = useState("");
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleResize = () => {
    if (window.innerWidth > 850) {
      setIsOpen(false);
    }

    setWindowWidth(window.innerWidth);
  };
  useLayoutEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavClass("is-sticky");
    } else {
      setnavClass("");
    }
  };
  const [activeLink, setActiveLink] = useState();
  useEffect(() => {
    const activation = (event) => {
      const target = event.target;
      if (target) {
        target.classList.add("active");
        setActiveLink(target);
        if (activeLink && activeLink !== target) {
          activeLink.classList.remove("active");
        }
      }
    };
    const defaultLink = document.querySelector(".navbar li.a.active");
    if (defaultLink) {
      defaultLink?.classList.add("active");
      setActiveLink(defaultLink);
    }
    const links = document.querySelectorAll(".navbar a");
    links.forEach((link) => {
      link.addEventListener("click", activation);
    });
    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", activation);
      });
    };
  }, [activeLink]);
  return (
    <nav
      className="Navbar"
      style={{
        justifyContent: !isOpen ? "center" : "right",
      }}
    >
      <div
        className="Navbar_Content"
        style={{
          flexDirection: isOpen ? "column" : "row",
        }}
      >
        <Link className="navbar-brand" to="/home">
          <div className="Navbar_Left">
            <div className="Navbar_Left_Logo">
              <img
                src={SikauneLogo}
                alt="Sikaune Logo"
                //   className="Navbar_Left_Logo"
              />
              <div className="Navbar_Left_Logo_Data">
                <h1 className="Navbar_Left_Heading">Sikaune</h1>
                <p className="Navbar_Left_Paragraph">You ask, we answer</p>
              </div>
            </div>
          </div>
        </Link>

        {/* <NavbarToggler
          className="navbar-toggler py-0 fs-20 text-body"
          onClick={toggle}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="mdi mdi-menu"></i>
        </NavbarToggler> */}
        <ul
          className="Navbar_Right"
          style={{
            flexDirection: isOpen ? "column" : "row",
            display: windowWidth < 850 ? (isOpen ? "flex" : "none") : "flex",
          }}
        >
          {!token ? (
            <div className="Navbar_Right_Button">
              <Link to="/login" className="Navbar_Right_Button_Primary">
                login
              </Link>
              <Link to="/register" className="Navbar_Right_Button_Secondary">
                Signup
              </Link>
            </div>
          ) : (
            <>
              <Link to="/set-wise" className="text-decoration-none">
                <li>Mock Test Set</li>
              </Link>
              <Link to="/chapter-wise" className="text-decoration-none">
                <li>MCQs</li>
              </Link>
              <Link to="/courses" className="text-decoration-none">
                <li>Recorded Class</li>
              </Link>
              <Link to="/classes" className="text-decoration-none">
                <li>Live Class</li>
              </Link>
              <Link to="/blogs" className="text-decoration-none">
                <li>Blogs</li>
              </Link>
              <span className="d-flex align-items-center">
                <ProfileDropdown />
              </span>
            </>
          )}

          {/* <div className="Navbar_Right_Box"> */}

          {/* </div> */}
        </ul>
        {windowWidth < 850 && (
          <div
            className="Navbar_Hamburger"
            style={{
              position: "absolute",
              right: "5px",
            }}
          >
            {!isOpen && (
              <img
                src="https://utfs.io/f/c297f18b-e2e8-4b6f-89ae-dd08c105ee0e-3kjk09.svg"
                alt="Hamburger"
                onClick={toggleMenu}
                className="Navbar_Hamburger_Image"
              />
            )}
            {isOpen && (
              <img
                src=" https://utfs.io/f/bd94b0c4-a79b-4960-ac82-15752e71405c-6u0i4t.svg"
                alt=" Cancel"
                onClick={toggleMenu}
                className="Navbar_Hamburger_Image"
              />
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
