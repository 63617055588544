import React, { useState } from "react";
import Navbar from "../../../Components/Navbar";
import { Col, Row, Card, CardHeader, CardBody, Input } from "reactstrap";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createVideoCourse, postBilling } from "../../../slices/Courses/thunk";
import { toast } from "react-toastify";
const AdminBillings = () => {
  const notify = () =>
    toast.success("Billing added successfully", {
      style: {
        backgroundColor: "#28a745" /* Custom green color */,
        color: "white" /* Adjust text color for visibility */,
      },
      icon: false /* Optionally, you can remove the default icon */,
    });
  const [billing, setBilling] = useState("");
  const [price, setPrice] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      billing,
      price,
    };

    const response = await dispatch(postBilling(formData));

    if (response.payload.status === "success") {
      setBilling("");
      notify();
      setPrice("");
    }
  };

  return (
  <React.Fragment>
      <div className="AdminDashboard_Main_Box">
        <div>
          <h1 className="AdminDashboard_Main_Box_Heading">Add Billing</h1>
          <p className="AdminDashboard_Main_Box_Paragraph">New Billing</p>
        </div>
        <input
          className="AdminDashboard_Main_Box_Right_Select"
          type="text"
          value={billing}
          onChange={(e) => setBilling(e.target.value)}
          placeholder="Billing Name"
        />
        <input
          className="AdminDashboard_Main_Box_Right_Select"
          type="text"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Billing Price"
        />
        <button
          className="AdminDashboard_Main_Box_Right_Button"
          onClick={handleSubmit}
        >
          Add Billing
        </button>
      </div>
    </React.Fragment>
  );
};

export default AdminBillings;
