import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import config from "../config";
import { postLogout } from "./backend_helper";
const { api } = config;

// default
axios.defaults.baseURL = api.API_URL;

// content type
// axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
// content type
const token = localStorage.getItem("token");
const deviceId = localStorage.getItem("user");
const clearStorage = () => {
  // await postLogout();
  localStorage.clear();
  delete axios.defaults.headers.common["Authorization"];
  
};

if (token) {
  const device = JSON.parse(deviceId);

  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  axios.defaults.headers.common["deviceId"] = device?.deviceId;
}
axios.interceptors.request.use(
  (config) => {
    // Ensure the `deviceId` header is added to every request
    if (deviceId) {
      const user = JSON.parse(deviceId);
      config.headers["deviceId"] = user?.deviceId;
    }

    // Add Authorization header if token is present
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    if (response.data.status === 0) {
      let message = { data: response.data.message };

      return Promise.reject(message);
    }
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;

    switch (error?.response?.status) {
      case 500:
        message = { data: "Internal Server Error" };
        break;
      case 403:
        message = { data: "Invalid credentials" };
        break;
      case 404:
        message = {
          data: "Sorry! the data you are looking for could not be found",
        };
        break;
      case 410: // Handle 410 Gone error
        message = { data: "Resource is no longer available" };
        clearStorage();
        break;

      default:
        if (error?.response?.data?.message === "Validation Error.") {
          message = { data: error?.response?.data?.data[0].msg };
        } else {
          message = { data: error?.response?.data?.message || error };
        }
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token, deviceId) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  axios.defaults.headers.common["deviceId"] = deviceId;
};

class APIClient {
  /**
   * Fetches data from the given URL
   */
  get = (url, params) => {
    let response;
    const token = localStorage.getItem("token");
    const deviceId = localStorage.getItem("user");
    const device = JSON.parse(deviceId);

    if (token && deviceId) {
      setAuthorization(token, device?.deviceId);
    }

    let paramKeys = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}?deviceId=${device?.deviceId}`, params);
    }

    return response;
  };

  /**
   * Posts the given data to the URL
   */
  create = (url, data) => {
    if (token && deviceId) {
      setAuthorization(token, deviceId?.deviceId);
    }
    if (data) {
      return axios.post(url, data);
    } else {
      return axios.post(url);
    }
  };

  /**
   * Updates data
   */
  update = (url, data) => {
    if (token && deviceId) {
      setAuthorization(token, deviceId?.deviceId);
    }
    return axios.patch(url, data);
  };

  put = (url, data) => {
    if (token && deviceId) {
      setAuthorization(token, deviceId?.deviceId);
    }
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */

  delete = (url, config) => {
    setAuthorization(token, deviceId?.deviceId);
    return axios.delete(url, { ...config });
  };
}

const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};
const getTokenForUser = () => {
  const token = sessionStorage.getItem("token");
  if (!token) {
    return null;
  } else {
    return token;
  }
};

export { APIClient, setAuthorization, getLoggedinUser, getTokenForUser };
