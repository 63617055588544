import React, { useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Home from "./Home";
import {useDispatch} from 'react-redux';
import { getSetCourse } from "../../slices/SetCourse/thunk";
import Footer from "../../Components/Footer";
const Index = () => {
  document.title = "Set-wise | Sikaune";

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getSetCourse());
  },[dispatch])
  return (
    <React.Fragment>
      <div className="layout-wrapper landing">
        <Navbar />
        <Home/>
        <Footer/>
     

        <button
          onClick={() => toTop()}
          className="btn btn-danger btn-icon landing-back-top"
          id="back-to-top"
        >
          <i className="ri-arrow-up-line"></i>
        </button>
      </div>
    </React.Fragment>
  );
};

export default Index;
