import React, { useEffect, useState } from "react";

import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";

import { useDispatch, useSelector } from "react-redux";
import { getVideoCourseById } from "../../slices/Courses/thunk";
import { useParams } from "react-router-dom";
import { VideoPlayer } from "../Courses/VideoPlayer";
import { Container, Row } from "reactstrap";
import ReactPlayer from "react-player";
import { Player } from 'video-react';

const Index = () => {
  const dispatch = useDispatch();
  //   let { videoId } = useParams;

  useEffect(() => {
    const videoId = localStorage.getItem("selectedId");


    dispatch(getVideoCourseById(videoId));
  }, []);
  const courseSelect = useSelector((state) => state.course);
  const { selectedCourseVideo } = courseSelect;

  return (
    <React.Fragment>
      <div className="layout-wrapper landing">
        <Navbar />
        {selectedCourseVideo !== null && (
          <section className="section pb-0 hero-section" id="hero" style={{ marginBottom: '20px', background: "#fff" }}>
            <Container>
              <Row className="justify-content-center" style={{ height: 'auto', minHeight:'600px' }}>
                <Player>
                  <source src={selectedCourseVideo?.videoLink}/>
                </Player>
                {/* <ReactPlayer
                  url={selectedCourseVideo?.videoLink}
                  playing
                  width="640"
                  height="360"
                  controls
                /> */}
              </Row>
            </Container>
          </section>
        )}
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Index;
