import React from "react";
import SikauneLogo from "../assets/images/SikauneLogo.png";
import { Link } from "react-router-dom";
const Footer1 = () => {
  return (
    <React.Fragment>
      <footer className="Footer">
        <div className="Footer_Content">
          <div className="Footer_Left">
            <div className="Footer_Left_Logo">
              <img
                src={SikauneLogo}
                alt="Sikaune Logo"
                //   className="Footer_Left_Logo"
              />
              <div className="Footer_Left_Logo_Data">
                <h1 className="Footer_Left_Heading">Sikaune</h1>
                <p className="Footer_Left_Paragraph">You ask, we answer</p>
              </div>
            </div>
            <div className="Footer_Left_Bottom">
              <h1 className="Footer_Left_Bottom_Heading">We are sikaune</h1>
              <p className="Footer_Left_Bottom_Paragraph">
                Sikaune is a group of engineers and educators developing online
                learning materials specializing in engineering fraternity.
              </p>
            </div>
          </div>
          <div className="Footer_Right">
            <div className="Footer_Right_Box">
              <h1 className="Footer_Right_Box_Heading">App Pages</h1>
              <ul className="Footer_Right_Box_List">
                <Link to="/set-wise" className="text-decoration-none">
                  <li>Mock Test Set</li>
                </Link>
                <Link to="/chapter-wise" className="text-decoration-none">
                  <li>MCQs</li>
                </Link>
                <Link to="/courses" className="text-decoration-none">
                  <li>Recorded Class</li>
                </Link>
                <Link to="/classes" className="text-decoration-none">
                  <li>Live Class</li>
                </Link>
              </ul>
            </div>
            <div className="Footer_Right_Box">
              <h1 className="Footer_Right_Box_Heading">Support</h1>
              <ul className="Footer_Right_Box_List">
                <li>FAQ</li>
                <li>MCQs</li>
                <li>Contact</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer1;
