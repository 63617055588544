import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import CustomTable1 from "../../../Components/Common/CustomTable1";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  deleteClassById,
  updateClassById,
} from "../../../slices/Courses/thunk";
import { deleteSetCourse, getSetCourse } from "../../../slices/SetCourse/thunk";

const AdminSetCourseDetails = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSetCourse());
  }, [dispatch]);
  const setCourse = useSelector((state) => state.set);

  const { sets } = setCourse;



  const columns = [
    {
      header: "Set Name",
      field: "className",
      width: "auto",
      truncate: true,
      className: "truncated-text",
      render: (item) => {
        return <p>NEC license preparation</p>;
      },
    },
    {
      header: "Total Question",
      field: "totalQuestions",
      width: "auto",
      truncate: true,
      className: "truncated-text",
    },
    {
      header: "Action",
      field: "scoring_percentage",
      width: "12%",
      truncate: false,
      render: (item) => {
        return (
          <div>
            <Button
              className="btn-danger"
              onClick={async (e) => {
                // dispatch(deleteClassById(item._id));
                const response = await dispatch(deleteSetCourse(item._id));
                if(response.payload.status === 'success'){
                    toast.success("Set deleted successfully");
                    window.location.reload();
                }
               
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="AdminUsers">
        {/* <div className="AdminUsers_Input"> */}
        <input type="text" className="AdminUsers_Input" placeholder="Search" />
        {/* </div> */}
        <button
          className="AdminUsers_Button"
          onClick={() => {
            navigate("/admin/set-course/create");
          }}
        >
          Add Sets
        </button>
      </div>
      <CustomTable1
        data={sets || []}
        columns={columns}
        onClick={(item) => {
          // setShowEmployee(item);
          // setBooleanEmployee(true);
        }}
      />
    </React.Fragment>
  );
};

export default AdminSetCourseDetails;
