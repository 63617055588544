import React, { useEffect, useState } from "react";
import { Card, CardBody, Alert } from "reactstrap";
import { useDispatch } from "react-redux";
import { changeDescription } from "../../slices/SetCourse/reducer";
// import { changeDescription, changeOption } from "../../slices/questionSlice";
// const c = option1, option2, option3, option4
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
const QuestionSubmit = ({
  id,
  question,
  options,
  selectedOption,
  selectedAnswer,
  isFinished,
  index,
  handleSelect,
  provideAnswer,
  answerGiven,
  showDescription,
}) => {
  const dispatch = useDispatch();

  const [isCorrect, setIsCorrect] = useState(false);
  const [isChoosenOption, setIsChoosenOption] = useState(null);
  useEffect(() => {
    //
    if (answerGiven !== null) {
      const providedAnswer = JSON.parse(answerGiven.providedAnswer);

      const isChooseOption = providedAnswer.find((el) => el.index === index);

      setIsChoosenOption(isChooseOption);
      if (isChooseOption?.selectedAnswer === selectedAnswer) {
        setIsCorrect(true);
      } else setIsCorrect(false);

      //
    }
  }, [answerGiven]);
  const getAlphabet = (index) => {
    switch (index) {
      case 0:
        return "a";
      case 1:
        return "b";
      case 2:
        return "c";
      case 3:
        return "d";
      case 4:
        return "e";
      default:
        return null;
    }
  };
  return (
    <Card
      style={{ background: "light-grey", height: "auto" }}
      className="Question"
    >
      <div className="d-flex align-items-center p-3">
        <h3 className="Question_Heading">
          {index + 1 + ".  "} {question}
        </h3>
      </div>
      <CardBody style={{ width:'100%'}}>
        {options.map((opt, subIndex) => (
          <div style={{ width:'100%'}}>
            <div className="d-flex align-items-center m-3 w-full">
              <input
                type="checkbox"
                checked={
                  selectedOption === opt.option ||
                  (isChoosenOption?.selectedAnswer === getAlphabet(subIndex) &&
                    answerGiven !== null)
                }
                className="h-5 w-5"
                style={{ height: "20px", width: "20px", marginRight: "5px" }}
                // onClick={() => dispatch(changeOption({ index, subIndex }))}
              />
              <p className="m-0 ml-3 mr-4 Question_Paragraph">{opt.option}</p>
              {selectedAnswer === getAlphabet(subIndex) &&
                answerGiven !== null && (
                  <FaCheck style={{ marginLeft: "30px" }} />
                )}
              <button
                className="ms-auto"
                style={{ border: "none", borderRadius: "5px", outline: "none" }}
                onClick={() => dispatch(changeDescription({ index, subIndex }))}
              >
                {subIndex === showDescription ? (
                  <FaChevronUp />
                ) : (
                  <FaChevronDown />
                )}
              </button>
            </div>
            {subIndex === showDescription && (
              <p className="text-muted">{opt.description}</p>
            )}
          </div>
        ))}
      </CardBody>
      <div>
        {isCorrect && answerGiven !== null && (
          <Alert color="success">
            <strong>Correct Answer</strong>
          </Alert>
        )}
        {!isCorrect && answerGiven !== null && (
          <Alert color="warning">
            <strong>Incorrect Answer</strong>
          </Alert>
        )}
      </div>
    </Card>
  );
};

export default QuestionSubmit;
