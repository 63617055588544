import { configureStore } from '@reduxjs/toolkit'
import courseReducer from './Courses/reducer';
import questionReducer from './questionSlice';
import setReducer from './SetCourse/reducer';
import blogReducer from './Blogs/reducer';
import userReducer from './User/reducer';
export const store = configureStore({
  reducer: {
    question: questionReducer,
    course: courseReducer,
    set: setReducer,
    blogs: blogReducer,
    users: userReducer,
  },
})