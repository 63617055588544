import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  createClasses,
} from "../../../slices/Courses/thunk";
import { toast } from "react-toastify";
const AdminClasses = () => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const notify = () =>
    toast.success("Class created Successfully", {
      style: {
        backgroundColor: "#28a745" /* Custom green color */,
        color: "white" /* Adjust text color for visibility */,
      },
      icon: false /* Optionally, you can remove the default icon */,
    });
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      className: name,
      link: url,
    };
    const response = await dispatch(createClasses(formData));

    if (response.payload.status === "success") {
      setUrl("");
      setName("");
      notify();
    }
  };
  //className
  //zoomLInk
  return (
    <React.Fragment>
      <div className="AdminDashboard_Main_Box">
        <div>
          <h1 className="AdminDashboard_Main_Box_Heading">Live Class</h1>
          <p className="AdminDashboard_Main_Box_Paragraph">Schedule a class</p>
        </div>
        <input
          className="AdminDashboard_Main_Box_Right_Select"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Class Name"
        />
        <input
          className="AdminDashboard_Main_Box_Right_Select"
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Class Link"
        />
        <button className="AdminDashboard_Main_Box_Right_Button" onClick={handleSubmit}>Add Class</button>
      </div>
    </React.Fragment>
  );
};

export default AdminClasses;
