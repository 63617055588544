import React from "react";

import { Container, Row, Col } from "reactstrap";
import BlogsCards from "./BlogsCard";
import { useLocation, useNavigate } from "react-router-dom";
import BlockDetails from "./BlogsDetails";
import { isAdmin } from "../../utils/isAdmin";
import { useSelector } from "react-redux";
import notFound from "../../assets/images/sad-face.png";
import NotFound from "../../Components/NotFound";
const Home = () => {
  const { hash } = useLocation();
  const id = hash.split("#").join("");
  const navigate = useNavigate();
  const blogSelect = useSelector((state) => state.blogs);
  const { blogs } = blogSelect;
  const navigatedTo = () => {
    navigate("/admin/blogs");
  };

  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <React.Fragment>
      <section className="TestSet">
        <div className="TestSet_Box">
          <div className="Classes_Box_Flex">
            <h1 className="TestSet_Box_Heading">Blogs</h1>
            {/* {isAdmin() && (
            <button
                className="Classes_Box_Content_Single_Button"
                onClick={() => navigatedTo()}
              >
                Upload New Set
              </button>
            )} */}
          </div>
          <div className="TestSet_Box_Content">
            {(!blogs || blogs?.length === 0) && (
              <NotFound text="Sorry, we don’t have any mock tests available for this subject." />
            )}
            <Row className="m-0">
              {blogs &&
                typeof blogs !== "string" &&
                blogs.map((content) => {
                  return (
                    <Col lg={6} xxl={4}>
                      <BlogsCards key={content._id} {...content} />
                    </Col>
                  );
                })}
            </Row>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
