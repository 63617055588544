import { createSlice } from "@reduxjs/toolkit";
import {
  getChapterModuleSet,
  getChapterSet,
  getChapterSets,
  getCourse,
  getSetCourse,
  getSetData,
  getSubmission,
  postChapterSet,
} from "./thunk";

const initialState = {
  sets: [],
  setStatus: 'loading',
  chapterSets: [],
  chapterSetStatus: 'loading',
  selectedSet: "",
  data: [],
  providedAnswer: null,
  selected: null,
  takenTest: [],
};

export const setCourseSlice = createSlice({
  name: "setCourse",
  initialState,
  reducers: {
    // selectSetCourse: (state, action) => {
    //   // 
    //   
    //   // const set = state.sets.filter(
    //   //   (set) => set._id === action.payload
    //   // )[0];
    //   // state.selectedSet = set;
    // },
    changeOption: (state, action) => {
      const { index, subIndex } = action.payload;
      const selectedOption = state.data[index].options[subIndex].option;
      state.data[index].selectedOption = selectedOption;
    },
    changeDescription: (state, action) => {
      const { index, subIndex } = action.payload;
      state.data[index].showDescription = subIndex;
    },
    clearSetData: (state, action) => {
      state.takenTest = [];
      state.data = [];
      state.selectedSet = "";
      state.providedAnswer = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSetCourse.fulfilled, (state, action) => {
      state.setStatus = 'completed';
      if (typeof action.payload?.data === "string") {
        state.sets = [];
      } else {
        state.sets = action.payload?.data;
      }
    });
    builder.addCase(getChapterSets.fulfilled, (state, action) => {
      state.chapterSetStatus = 'completed';
      state.chapterSets = action.payload?.data;
    });
    builder.addCase(getChapterSet.fulfilled, (state, action) => {
      state.selectedSet = action.payload?.data;
      if(typeof action.payload.data !== 'string'){
        state.data = JSON.parse(action.payload.data?.questionSets);
      }
      
    });
    builder.addCase(getSubmission.fulfilled, (state, action) => {
      state.takenTest = action.payload?.data;
    });
    builder.addCase(getChapterModuleSet.fulfilled, (state, action) => {
  
      state.selectedSet = action.payload?.data;
      if (typeof action.payload.data !== "string") {
        state.data = JSON.parse(action.payload?.data?.questionSets);
      }
      
    });
    builder.addCase(getSetData.fulfilled, (state, action) => {
      state.providedAnswer = action.payload?.data;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  // selectSetCourse,
  changeDescription,
  changeOption,
  clearSetData,
} = setCourseSlice.actions;

export default setCourseSlice.reducer;
