import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar";
import { Col, Row, Card, CardHeader, CardBody, Input } from "reactstrap";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createVideoCourse, getCourse } from "../../../slices/Courses/thunk";
import { toast } from "react-toastify";
const AdminBlogs = () => {
  const notify = () =>
    toast.success("Courses Added Successfully", {
      style: {
        backgroundColor: "#28a745" /* Custom green color */,
        color: "white" /* Adjust text color for visibility */,
      },
      icon: false /* Optionally, you can remove the default icon */,
    });
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const courses = useSelector((state) => state.course.courses);

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [chapterOptions, setChapterOptions] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedCourse && selectedChapter) {
      const chapterId = selectedCourse._id;
      const subModuleId = selectedChapter._id;
    } else {
      toast.error("Please select course and chapter");
      return;
    }
    // if (selectedFiles.length === 0) {
    //   toast.error("Please select a file");
    //   return;
    // }
    const chapterId = selectedCourse._id;
    const subModuleId = selectedChapter._id;
    const formData = {
      chapterId,
      subModuleId,
      name,
      url,
    };
    const response = await dispatch(createVideoCourse(formData));

    if (response.payload.status === "success") {
      notify();
      setUrl("");
      setName("");
    }
  };

  const handleCourseChange = (event) => {
    const courseId = event.target.value;
    const selectedCourseObject = courses.find(
      (course) => course._id === courseId
    );
    setSelectedCourse(selectedCourseObject);
    setSelectedChapter(null);
    setChapterOptions(selectedCourseObject.subModules);
  };

  const handleChapterChange = (event) => {
    const chapterId = event.target.value;
    const selectedChapterObject = chapterOptions.find(
      (chapter) => chapter._id === chapterId
    );
    setSelectedChapter(selectedChapterObject);
  };
  useEffect(() => {
    dispatch(getCourse());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="AdminDashboard_Main_Box">
        <h1 className="AdminDashboard_Main_Box_Heading">Upload Videos</h1>
        <div className="AdminDashboard_Main_Box_Box">
          <div className="AdminDashboard_Main_Box_Hello">
          <input
          className="AdminDashboard_Main_Box_Right_Select"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Video Name"
        />
         <input
          className="AdminDashboard_Main_Box_Right_Select"
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Video Url"
        />
          </div>
          <div className="AdminDashboard_Main_Box_Right">
            <select
              className="AdminDashboard_Main_Box_Right_Select"
              value={selectedCourse ? selectedCourse._id : ""}
              onChange={handleCourseChange}
            >
              <option value="">Select Course</option>
              {courses &&
                courses.map((course) => (
                  <option key={course._id} value={course._id}>
                    {course.chapterName}
                  </option>
                ))}
            </select>

            <select
              className="AdminDashboard_Main_Box_Right_Select"
              value={selectedChapter ? selectedChapter._id : ""}
              onChange={handleChapterChange}
            >
              <option value="">Select Chapter</option>
              {chapterOptions.map((chapter) => (
                <option key={chapter._id} value={chapter._id}>
                  {chapter.moduleName}
                </option>
              ))}
            </select>
            <button
              className="AdminDashboard_Main_Box_Right_Button"
              onClick={handleSubmit}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminBlogs;
