import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import CustomTable1 from "../../../Components/Common/CustomTable1";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  createUsers,
  deleteUser,
  editUsers,
  getAllUsers,
} from "../../../slices/User/thunk";
import { deleteUserReducer } from "../../../slices/User/reducer";
import Navbar1 from "../../../Components/Navbar1";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  deleteBilling,
  getBillings,
  updateBillingsById,
} from "../../../slices/Courses/thunk";

const AdminBillingsDetails = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBillings());
  }, [dispatch]);
  const course = useSelector((state) => state.course);

  const { billings } = course;
  const [open, setOpen] = useState("");
  const [billingName, setBillingName] = useState("");
  const [price, setPrice] = useState("");
  const [data, setData] = useState();

  const toggle = () => setOpen("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data1 = {
      ...data,
      billing: billingName ? billingName : data.billing,
      price: price ? price : data.price,
    };
    const response = await dispatch(updateBillingsById(data1));

    console.log(response, "response is this ");

    // toggle();
    if (response.payload.status === "success") {
      toast.success("Billings updated successfully", { autoClose: 1000 });
      window.location.reload();
    }
  };

  const columns = [
    {
      header: "Billing Name",
      field: "billing",
      width: "auto",
      truncate: true,
      className: "truncated-text",
    },
    {
      header: "Price",
      field: "price",
      width: "auto",
      truncate: true,
      className: "truncated-text",
    },
    {
      header: "Action",
      field: "scoring_percentage",
      width: "12%",
      truncate: false,
      render: (item) => {
        return (
          <div>
            <Modal
              isOpen={open === item?._id}
              toggle={toggle}
              centered
              className="Modal"
            >
              <ModalBody className="Modal_Body">
                <h1 className="AddUser_Heading">Edit Billings Information</h1>

                <div className="AddUser_Input">
                  <p className="AddUser_Input_Name">Billing Name</p>
                  <input
                    className="AddUser_Input_Input"
                    value={billingName || data?.billing}
                    onChange={(e) => {
                      setBillingName(e.target.value);
                    }}
                  />
                </div>
                <div className="AddUser_Input">
                  <p className="AddUser_Input_Name">Price</p>
                  <input
                    className="AddUser_Input_Input"
                    value={price || data?.price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                    }}
                  />
                </div>

                <button className="AddUser_Button" onClick={handleSubmit}>
                  Edit Billings
                </button>
              </ModalBody>
            </Modal>
            <Button
              className="btn-primary"
              onClick={() => {
                setOpen(item?._id);
                setData(item);
                // setBillingName(item.billing);
                // setPrice(item.price);
              }}
            >
              Edit
            </Button>
            <Button
              className="btn-danger"
              onClick={async(e) => {
                // e.preventDefault();
                // dispatch(deleteUserReducer(item?._id));
                // dispatch(deleteUser(item?._id));
                const response = await dispatch(deleteBilling(item._id));
                if(response.payload.status === 'success'){
                  toast.success("User deleted successfully");
                  window.location.reload();
                }
                
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="AdminUsers">
        {/* <div className="AdminUsers_Input"> */}
        <input type="text" className="AdminUsers_Input" placeholder="Search" />
        {/* </div> */}
        <button
          className="AdminUsers_Button"
          onClick={() => {
            navigate("/admin/billings/create");
          }}
        >
          Add Billings
        </button>
      </div>
      <CustomTable1
        data={billings || []}
        columns={columns}
        onClick={(item) => {
          // setShowEmployee(item);
          // setBooleanEmployee(true);
        }}
      />
    </React.Fragment>
  );
};

export default AdminBillingsDetails;
