import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";

//import images
import avatar1 from "../../assets/images/noProfile.jpeg";
import { logoutUser } from "../../slices/User/thunk";
import axios from "axios";
import { isAdmin } from "../../utils/isAdmin";
const ProfileDropdown = () => {
  const [userName, setUserName] = useState("Hello");

  useEffect(() => {
    const user = localStorage.getItem("user");
    setUserName(JSON.parse(user)?.name);
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  const logout = async () => {
    const response = await dispatch(logoutUser());

    if (response.payload.status === "success") {
      localStorage.clear();
      navigate("/login");
      delete axios.defaults.headers.common["Authorization"];
      window.location.reload();
    }
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item "
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              style={{ height: "30px", width: "auto" }}
              src={avatar1}
              alt="Header Avatar"
            />
            {/* <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {" "}
                {userName.split(" ")[0] || "Admin"}
              </span>
            </span> */}
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome {userName}!</h6>

          <DropdownItem className="p-0">
            <Link to="/buy-now" className="dropdown-item">
              <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle">Billings</span>
            </Link>
          </DropdownItem>
          {isAdmin() && (
            <DropdownItem className="p-0">
              <Link to="/admin" className="dropdown-item">
                <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
                <span className="align-middle">Admin</span>
              </Link>
            </DropdownItem>
          )}
          <DropdownItem className="p-0">
            <Link to="/submission" className="dropdown-item">
              <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle">Submission</span>
            </Link>
          </DropdownItem>
          <div className="dropdown-divider"></div>

          <DropdownItem className="p-0">
            <span className="dropdown-item" onClick={() => logout()}>
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-logout">
                Logout
              </span>
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
