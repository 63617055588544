import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createUserApi,
  deleteUserApi,
  editUserApi,
  getAllUsersApi,
  postCheckOtp,
  postCheckTransaction,
  postInitiateTransaction,
  postLoginUser,
  postLogout,
  postRegisterUser,
  postResendOtp,
} from "../../helpers/backend_helper";
export const createUsers = createAsyncThunk("users/createUsers", async (data) => {
  try {
    const response = await createUserApi(data);
    return response;
  } catch (error) {
    return error;
  }
});
export const editUsers = createAsyncThunk("users/editUsers", async (data) => {
  try {
    const response = await editUserApi(data._id, data);
    return response;
  } catch (error) {
    return error;
  }
});
export const getAllUsers = createAsyncThunk("users/getAllUsers", async () => {
  try {
    const response = await getAllUsersApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const deleteUser = createAsyncThunk("users/getAllUsers", async (id) => {
  try {
    const response = await deleteUserApi(id);
    return response;
  } catch (error) {
    return error;
  }
});

export const logoutUser = createAsyncThunk("users/loginUser", async (data) => {
  try {
    const response = await postLogout();
    return response;
  } catch (error) {
    return error;
  }
});
export const loginUser = createAsyncThunk("users/loginUser", async (data) => {
  try {
    const response = await postLoginUser(data);

    return response;
  } catch (error) {
    return error;
  }
});
export const checkTransaction = createAsyncThunk(
  "users/checkTransaction",
  async (data) => {
    try {
      const response = await postCheckTransaction(data);

      return response;
    } catch (error) {
      return error;
    }
  }
);
export const initiateTransaction = createAsyncThunk(
  "users/initateTransaction",
  async (data) => {
    try {
      const response = await postInitiateTransaction(data);

      return response;
    } catch (error) {
      return error;
    }
  }
);
export const registerUser = createAsyncThunk(
  "users/registerUser",
  async (data) => {
    try {
      const response = await postRegisterUser(data);

      return response;
    } catch (error) {
      return error;
    }
  }
);
export const checkOtpUser = createAsyncThunk(
  "users/checkOtpUser",
  async (data) => {
    try {
      const response = await postCheckOtp(data);

      return response;
    } catch (error) {
      return error;
    }
  }
);
export const resendOtp = createAsyncThunk("users/resendOtp", async (data) => {
  try {
    const response = await postResendOtp(data);

    return response;
  } catch (error) {
    return error;
  }
});
