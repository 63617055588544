import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { turncate } from "../../utils/string";
import img6 from "../../assets/images/small/img-6.jpg";
import TruncateText from "../../helpers/truncatedText";
import { hasPaid } from "../../utils/hasPaid";
const BlogsCards = ({ _id, src, alt, title, description }) => {
  const navigate = useNavigate();
  const navigateToBlogs = () =>{
    // if(hasPaid()){
      navigate(`/blogs/${_id}`);
    // }else{
    //   navigate('/buy-now');
    // }
   
  } 
  return (
    <Card onClick={()=>{navigateToBlogs()}}>
      <img className="card-img-top img-fluid" src={img6} alt={alt} />
      <CardBody>
        <h4 className="card-title mb-2">{title}</h4>
        <p className="card-text text-muted truncate">
          {" "}
          <TruncateText text={description} maxLength={200}/>
        </p>
      </CardBody>
    </Card>
  );
};

export default BlogsCards;
