import "./App.css";
import "./assets/scss/themes.scss";
import Route from "./Routes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./Routes/scrollToTop";

function App() {
  return (
    <>
      <ToastContainer />
      <ScrollToTop/>
      <Route />
    </>
  );
}

export default App;
