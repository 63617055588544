import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getBlogsById } from "../../slices/Blogs/thunk";
import Navbar from "../../Components/Navbar";
import { Card, CardBody, CardHeader, Container, Row } from "reactstrap";
import Footer from "../../Components/Footer";
const BlogCard = ({ blogs }) => {
  return (
    <Card>
      <CardBody>
        <h3 className="fs-20 text-black">{blogs?.heading}</h3>
        {/* {blogs?.paragraph  && blogs?.paragraph.map((para)=>{
                    return <p className="fs-14 text-muted">{para}</p>
                })} */}
      </CardBody>
    </Card>
  );
};
const Index = () => {
  const [blogs, setBlogs] = useState(null);

  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBlogsById(id));
  }, []);
  const blogSelect = useSelector((state) => state.blogs);
  const { selectedBlog } = blogSelect;
  useEffect(() => {
    if (selectedBlog !== null) {
      const textContent = new DOMParser().parseFromString(
        selectedBlog.blogBody,
        "text/html"
      ).body.textContent;
      setBlogs(textContent);
      // setBlogs(JSON.parse(selectedBlog?.blogBody));
    }
  }, [selectedBlog]);

  return (
    <div className="layout-wrapper landing">
      <Navbar />
      <section
        className="TestSet"
        style={{ minHeight: "80vh", maxWidth: "700px", margin: "20px auto" }}
      >
        <div className="TestSet_Box">
          <div className="Classes_Box_Flex">
            <h1 className="TestSet_Box_Heading">{selectedBlog?.title}</h1>
          </div>
          <div
            className="Blogs_Paragraph"
            dangerouslySetInnerHTML={{ __html: blogs }}
          />
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Index;
