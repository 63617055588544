import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import khalti from "../../assets/images/khalti.jpg";
import Khalti from "../../assets/images/khaltui.png";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getBillings } from "../../slices/Courses/thunk";
import { setAuthorization } from "../../helpers/api_helper";
import config from "../../config";
import { checkTransaction, initiateTransaction } from "../../slices/User/thunk";
import { isAdmin } from "../../utils/isAdmin";
const Index = () => {
  const [price, setPrice] = useState("0");
  const [loading, setLoading] = useState(false);

  const [subscriptionId, setSubscriptionId] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const dispatch = useDispatch();
  const { api } = config;
  const navigate = useNavigate();
 
  const navigatedTo = () => {
    navigate("/admin/billings");
  };

  useEffect(() => {
    dispatch(getBillings());
  }, [dispatch]);
  const token = localStorage.getItem("token");
  const deviceId = localStorage.getItem("user");
  useEffect(()=>{
    if(!deviceId){
      navigate('/login');
    }
  })
  const device = JSON.parse(deviceId);
  setAuthorization(token, device?.deviceId);
  const onClick = async () => {
    setLoading(true);
    // checkout.show({amount: 100000}); //amount in paisa
    const data = {
      // return_url: `http://localhost:3000/payment-success?subscriptionId=${selectedOption._id}`,
      // website_url: "http://localhost:3000/",
      return_url: `https://sikaune.com/payment-success?subscriptionId=${selectedOption._id}`,
      website_url: "https://sikaune.com/",
      amount: `${parseInt(selectedOption?.price) * 100}`,
      purchase_order_id: "Order01",
      purchase_order_name: "test",
    };
    const response = await dispatch(initiateTransaction(data));

    setLoading(false);
    window.location.href = response.payload.data;
  };
  const course = useSelector((state) => state.course);

  const { billings } = course;
  return (
    <div>
      <Navbar />
      <section className="Billing">
        <div className="Billing_Content">
          <h1 className="Billing_Heading">Billing</h1>
          <div className="Billing_Box">
            <p className="Billing_Box_Paragraph">
              Complete your payment process
            </p>
            <select
              className="Billing_Box_Input"
              onChange={(e) => {
                const value = e.target.value;

                const billing = billings?.filter(
                  (billing) => billing._id === value
                );
                setSelectedOption(billing[0]);
              }}
            >
              <option value="">Select Subscription</option>
              {billings !== null &&
                typeof billings !== "string" &&
                billings.length > 0 &&
                billings?.map((billing) => (
                  <option value={billing._id}>{billing.billing}</option>
                ))}
            </select>
            <input
              className="Billing_Box_Input"
              value={selectedOption?.price || 0}
            />
            <div className="Billing_Box_PromoCode">
              <input className="Billing_Box_PromoCode_Input" placeholder="Promocode"/>
              <button className="Billing_Box_PromoCode_Button">Apply</button>
            </div>
            {selectedOption?.price > 0 && (
              <div className="Billing_Box_Amount">
                <div className="Billing_Box_Amount_Text">
                  <div className="Billing_Box_Amount_Text1">
                    <p className="Billing_Box_Amount_Text1_Paragraph">
                      Subtotal
                    </p>
                    <strong className="Billing_Box_Amount_Text1_Strong">
                      Rs.{selectedOption?.price || 0}
                    </strong>
                  </div>
                  <div className="Billing_Box_Amount_Text1">
                    <p className="Billing_Box_Amount_Text1_Paragraph">
                      Discount
                    </p>
                    <strong className="Billing_Box_Amount_Text1_Strong">
                      - Rs. 0.00
                    </strong>
                  </div>
                </div>
                <div className="Billing_Box_Amount_Text1">
                  <p className="Billing_Box_Amount_Text1_Paragraph">Total</p>
                  <strong className="Billing_Box_Amount_Text1_Strong">
                    Rs. {selectedOption?.price || 0}
                  </strong>
                </div>
              </div>
            )}
          </div>
          <div className="Billing_Payment">
            <p className="Billing_Payment_Paragraph">Payment Option</p>
            <div className="Billing_Payment_Box" onClick={onClick}>
              <img
                src={Khalti}
                alt="Sikaune Payment"
                className="Billing_Payment_Box_Image"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section
        className="section pb-0 hero-section mb-12 mt-12"
        id="hero"
        style={{ minHeight: "80vh" }}
      >
        <Container>
          <Row className="justify-content-between">
            <Col sm={6} md={6} className="mb-3">
              <h4 className="card-title" style={{ marginTop: "50px" }}>
                Billing Section
              </h4>
            </Col>
            {isAdmin() && (
              <Col sm={6} md={6} className="d-flex justify-content-end mb-3">
                <button
                  className="btn btn-success"
                  onClick={() => navigatedTo()}
                >
                  Upload New Billing
                </button>
              </Col>
            )}
          </Row>
          <Row className="mt-4 justify-content-between">
            <Card
              className="pricing-box ribbon-box ribbon-fill text-center"
            >
              <Row className="g-0">
                <Col lg={12}>
                  <CardBody className="h-100">
                    <select
                      className="form-select mb-3"
                      onChange={(e) => {
                        const value = e.target.value;

                        const billing = billings?.filter(
                          (billing) => billing._id === value
                        );
                        setSelectedOption(billing[0]);
                      }}
                    >
                      <option>Select Subscription</option>
                      {billings !== null && typeof billings !== 'string' && billings.length > 0 &&
                        billings?.map((billing) => (
                          <option value={billing._id}>{billing.billing}</option>
                        ))}
                    </select>
                    <input
                      className="form-select mb-3"
                      value={selectedOption?.price || 0}
                    />
                    {loading === false && selectedOption.price > 0 ? (
                      <div
                        className="text-center plan-btn mt-2"
                        onClick={onClick}
                      >
                        <Link
                          to="#"
                          className="d-flex flex-column btn btn-success w-sm waves-effect waves-light"
                          style={{
                            width: "100%",
                            height: "auto",
                            margin: "0 auto",
                          }}
                        >
                          <img
                            src={khalti}
                            alt="khalti pay"
                            style={{
                              width: "150px",
                              margin: "0 auto",
                              height: "auto",
                            }}
                          />
                          <p className=" fw-bold fs-16 m-0">
                            {" "}
                            {selectedOption?.price > 0
                              ? "Pay VIa Khalti"
                              : "Select Plan"}
                          </p>
                        </Link>
                      </div>
                    ) : (
                      <div className="text-center plan-btn mt-2">
                        <Link
                          to="#"
                          className="d-flex flex-column btn btn-success w-sm waves-effect waves-light"
                          style={{
                            width: "100%",
                            height: "auto",
                            margin: "0 auto",
                          }}
                        >
                          <img
                            src={khalti}
                            alt="khalti pay"
                            style={{
                              width: "150px",
                              margin: "0 auto",
                              height: "auto",
                            }}
                          />
                          <p className=" fw-bold fs-16 m-0">
                            {selectedOption?.price > 0
                              ? "Loading"
                              : "Select Plan"}
                          </p>
                        </Link>
                      </div>
                    )}
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Row>
        </Container>
      </section> */}
      <Footer />
    </div>
  );
};

export default Index;
