import React, { useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVideoCourses } from "../../slices/Courses/thunk";
import NEC_LOGO from "../../assets/images/NEC_logo.gif";
import notFound from "../../assets/images/sad-face.png";
import { selectCourse } from "../../slices/Courses/reducer";
import { hasPaid } from "../../utils/hasPaid";
import NotFound from "../../Components/NotFound";
const Course = ({ video }) => {
  const navigate = useNavigate();
  const setQuestion = (payload) => {
    if (hasPaid()) {
      localStorage.setItem("selectedId", video._id);
      navigate(`/courses/${video._id}`);
    } else {
      navigate("/buy-now");
    }
  };

  return (
    <Col className="list-element" key={1} lg={4} md={6} sm={12}>
      <Card className="explore-box card-animate">
        <div className="explore-place-bid-img">
          <input type="hidden" className="form-control" id="1" />
          <div className="d-none">undefined</div>
          <img src={NEC_LOGO} alt="" className="card-img-top explore-img" />
          <div className="bg-overlay"></div>
          <div className="place-bid-btn">
            <button className="btn btn-success" onClick={setQuestion}>
              <i className="ri-auction-fill align-bottom me-1"></i>Watch Video
            </button>
          </div>
        </div>
        <CardBody>
          <h5 className="mb-1">
            <Link
              to="/course"
              //   to={course.course_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {video?.title}
            </Link>
          </h5>
          <h5 className="m-0">
            <small className="text-muted">Civil Engineering</small>
          </h5>
        </CardBody>
      </Card>
    </Col>
  );
};
const SinglePageCourse = () => {
  document.title = "Course| Sikaune";
  let { chapterId, subModuleId } = useParams();
  const dispatch = useDispatch();
  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  useEffect(() => {
    dispatch(
      getVideoCourses({ chapterId: chapterId, subModuleId: subModuleId })
    );
  }, [dispatch, chapterId, subModuleId]);
  const course = useSelector((state) => state.course);

  const { courseVideos } = course;

  return (
    <React.Fragment>
      <div className="layout-wrapper landing">
        <Navbar />
        <section className="TestSet">
        <div className="TestSet_Box">
          <div className="Classes_Box_Flex">
            <h1 className="TestSet_Box_Heading">Courses Videos</h1>
 
          </div>
          <div className="TestSet_Box_Content">
           
          {courseVideos.length > 0 &&
                typeof courseVideos !== "string" &&
                courseVideos?.map((courseVideo, index) => {
                  return <Course video={courseVideo} />;
                })}
              {(courseVideos.length === 0 ||
                typeof courseVideos === "string") && (
                  <NotFound text="Sorry, we don’t have any courses available for this subject." />
              )}
          </div>
        </div>
      </section>


        <Footer />

        <button
          onClick={() => toTop()}
          className="btn btn-danger btn-icon landing-back-top"
          id="back-to-top"
        >
          <i className="ri-arrow-up-line"></i>
        </button>
      </div>
    </React.Fragment>
  );
};

export default SinglePageCourse;
