import React from 'react'

import { Link } from "react-router-dom";
import Illustration from '../../assets/images/Illustration.svg';
const JoinToday = () => {

  return (
    <section className="JoinToday">
      <img src={Illustration} alt="Illustration" className='JoinToday_Image'/>
      <div className="JoinToday_Content">
        <h1 className="JoinToday_Content_Heading">
          Nothing prepares you for tomorrow better than{" "}
          <span className="JoinToday_Content_Span"> Sikaune</span>
        </h1>
        <p className="JoinToday_Content_Paragraph">
          Learn from the best in their field, from anywhere, and grow
          professionally.
        </p>
        <Link to="/buy-now">
          <button className="JoinToday_Content_Button">Join Today</button>
        </Link>
      </div>
    </section>
  );
};

export default JoinToday;
