import React, { useEffect } from "react";
import { Card, CardBody, Button, Container, Row, Badge } from "reactstrap";
import CustomTable1 from "../../Components/Common/CustomTable1";
import Navbar from "../../Components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getSubmission } from "../../slices/SetCourse/thunk";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import { hasPaid } from "../../utils/hasPaid";
import Search from "../../assets/images/Search.svg";

const Index = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getSubmission(user._id));
  }, []);
  const setQuestion = useSelector((state) => state.set);
  const { takenTest } = setQuestion;
  const columns = [
    {
      header: "Set name",
      field: "course_name",
      width: "auto",
      truncate: true,
      className: "truncated-text",
      render: (item) => {
        return (
          <h6 className="Table_Body_Text">
            {item.chapterSetId
              ? item.chapterSetId.courseId.chapterName
              : "NEC license exam preparation"}
          </h6>
        );
      },
    },

    {
      header: "Marks Scored",
      field: "exam_status",
      width: "19%",
      truncate: false,
      render: (item) => {
        return <p className="Table_Body_Text1">{item.totalCorrectAnswer}</p>;
      },
    },
    {
      header: "Status",
      field: "scoring_percentage",
      width: "19%",
      truncate: false,
      render: (item) => {
        const percent =
          (parseInt(item.totalCorrectAnswer) / parseInt(item.totalQuestion)) *
          100;
        // return <p>{percent}</p>;
        return percent < 40 ? (
          <button className="Table_Body_Danger">Fail</button>
        ) : (
          <button className="Table_Body_Success">Passed</button>
        );
      },
    },
    {
      header: "Action",
      field: "scoring_percentage",
      width: "19%",
      truncate: false,
      render: (item) => {
        return (
          <Button
            className="Table_Body_Primary"
            onClick={() => {
              if (hasPaid()) {
                if (item.setId !== undefined) {
                  navigate(`/set-wise/${item.setId}?setDataId=${item._id}`);
                } else {
                  navigate(
                    `/chapter-wise/${item.chapterSetId._id}?setDataId=${item._id}`
                  );
                }
              } else {
                navigate("/buy-now");
              }
            }}
          >
            Check Answer
          </Button>
        );
      },
    },
  ];
  return (
    <div>
      <Navbar />
      <section className="Submission">
        <div className="Submission_Content">
          <h1 className="Submission_Heading">Submission</h1>
          <div className="Submission_Box">
            <div className="Submission_Box_Top">
              <p className="Submission_Box_Top_Paragraph">Your Results</p>
              <div className="Submission_Box_Top_Box">
                <div className="Submission_Box_Top_InputBox">
                  <img
                    src={Search}
                    alt="Sikaune Search"
                    className="Submission_Box_Top_InputBox_Image"
                  />
                  <input
                    placeholder="Search"
                    className="Submission_Box_Top_InputBox_Input"
                  />
                </div>
              </div>
            </div>
            <div className="Submission_Box_Bottom">
              <CustomTable1
                data={takenTest || []}
                columns={columns}
                onClick={(item) => {
                  // setShowEmployee(item);
                  // setBooleanEmployee(true);
                }}
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section
        className="section pb-0 hero-section"
        id="hero"
        style={{ minHeight: "80vh" }}
      >
        <Container>
          <Row
            className="mt-4 justify-content-between"
          
          >
            <Card  style={{ marginTop: "30px" }}>
              <CardBody>
                <CustomTable1
                  data={takenTest || []}
                  columns={columns}
                  onClick={(item) => {
                    // setShowEmployee(item);
                    // setBooleanEmployee(true);
                  }}
                />
              </CardBody>
            </Card>
          </Row>
        </Container>
      </section> */}
      <Footer />
    </div>
  );
};

export default Index;
