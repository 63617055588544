import React from "react";
import { Navigate } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import RedirectIfNoPayment from "../utils/RedirectIfNoPayment";
const AuthProtected = (props) => {
  const token = localStorage.getItem("token");
  const userProfile = JSON.parse(localStorage.getItem("user"));
  if (!token || !userProfile) {
    
    return <Navigate to="/login" />;
  }
  setAuthorization(token, userProfile?.deviceId);

  return <RedirectIfNoPayment>{props.children}</RedirectIfNoPayment>;
};

export default AuthProtected;
