import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Landing from "./Landing";
import GoFar from "./GoFar";
import About from "./About";
import JoinToday from "./JoinToday";
import Slider from "react-slick";
import Moments from "./Moments";
import ImageHalf from "../../assets/images/CircleHalf.svg";
import { Link } from "react-router-dom";
const LandingHome = () => {
  document.title = "Sikaune| Home";

  window.onscroll = function () {
    scrollFunction();
  };



  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  return (
    <div>
      {/* <div className="layout-wrapper landing"> */}
      {/*

*/}
      <Navbar />
      <Landing />
      <GoFar />
      <About />
      <JoinToday />

      <Moments />
      <section className="Boost">
        <div className="Boost_Content">
          <div className="Boost_Content_Box">
            <img
              src={ImageHalf}
              className="Boost_Content_Box_Image"
              alt="SIkaune"
            />
            <div className="Boost_Content_Box_Box">
              <h1 className="Boost_Content_Box_Heading">
                Boost your confidence by solving test sets.
              </h1>
              <p className="Boost_Content_Box_Paragraph">
                Explore our vast collection of test materials, enhance your
                skills, and be fully prepared.
              </p>
              <Link to="/buy-now">
                <button className="Boost_Content_Box_Button">Join Today</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      {/*
     
     
     
     
      
     
      
  */}
    </div>
  );
};

export default LandingHome;
