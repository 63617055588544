import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllChapterSets,
  getAllSets,
  getSet,
  postSet,
  postSetCourse,
  getSetDataApi,
  postChapterSetCourse,
  postChapterModule,
  getChapterModuleSetApi,
  getSubmissionApi,
  deleteSetApi
} from "../../helpers/backend_helper";
export const getSetCourse = createAsyncThunk(
  "setCourse/getSetCourse",
  async () => {
    try {
      const response = await getAllSets();
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const deleteSetCourse = createAsyncThunk(
  "setCourse/deleteSetCourse",
  async (setId) => {
    try {
      const response = await deleteSetApi(setId)
      
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getChapterSets = createAsyncThunk(
  "setCourse/getChapterSets",
  async (data) => {
    try {
      const response = await getAllChapterSets(data);
      return response || "";
    } catch (error) {
      return error;
    }
  }
);
export const getSetData = createAsyncThunk(
  "setCourse/getSetData",
  async (data) => {
    try {
      const response = await getSetDataApi(data)
      return response || "";
    } catch (error) {
      return error;
    }
  }
);
export const getChapterSet = createAsyncThunk(
  "setCourse/getChapterSet",
  async (id) => {
    try {
      const response = await getSet(id);

      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getChapterModuleSet = createAsyncThunk(
  "setCourse/getChapterModuleSet",
  async (id) => {
    try {
      const response = await getChapterModuleSetApi(id);

      return response;
    } catch (error) {
      return error;
    }
  }
);
export const postChapterSet = createAsyncThunk(
  "setCourse/postChapterSet",
  async (data) => {
    try {
      const { id, postData } = data;
      const response = await postSet(id, postData)

      if(response.status === 'success'){
        return true;
      }
      else{
        return false;
      }
    } catch (error) {
      return error;
    }
  }
);

 

export const addSetCourse = createAsyncThunk(
  "setCourse/addSetCourse",
  async (data) => {
    try {
      const response = await postSetCourse(data);

      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getSubmission = createAsyncThunk(
  "setCourse/getSubmission",
  async (id) => {
    try {
      const response = await getSubmissionApi(id);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addChapterSetCourse = createAsyncThunk(
  "setCourse/addChapterSetCourse",
  async (data) => {
    try {
      const response = await postChapterSetCourse(data);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addChapterModule = createAsyncThunk(
  "setCourse/addChapterModule",
  async (data) => {
    try {
      const response = await postChapterModule(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

