import React, { useState } from "react";
import { useStopwatch } from "react-timer-hook";
import { Card, CardBody } from "reactstrap";
import { useCountdown } from "../../utils/useCountdown";

const Timer = () => {
  const [futureDate, setFutureDate] = useState(() => {
    // Calculate futureDate only when the component mounts
    let currentDate = new Date();
    return new Date(currentDate.getTime() + 120 * 60 * 1000);
  });

  const [days, hours, minutes, seconds] = useCountdown(futureDate);
  return (
    <div className="Landing1_Left_Top" style={{ display: "flex" }}>
      <div className="Landing1_Left_Top_Time">
        <span className="Landing1_Left_Top_Timer">{hours}</span>:
        <span className="Landing1_Left_Top_Timer">{minutes}</span>:
        <span className="Landing1_Left_Top_Timer">{seconds}</span>
      </div>
      <p className="Landing1_Left_Top_Paragraph">remaining</p>
    </div>
  );
};

export default Timer;
