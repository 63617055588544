import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Row,
  UncontrolledCollapse,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { FaChevronRight, FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { FaLongArrowAltRight } from "react-icons/fa";
import { selectCourse } from "../../slices/Courses/reducer";
import { useNavigate } from "react-router-dom";
import notFound from "../../assets/images/sad-face.png";
import { isAdmin } from "../../utils/isAdmin";
import { hasPaid } from "../../utils/hasPaid";
import NotFound from "../../Components/NotFound";
const Modules = ({ id, chapterName, modules, subModules, selectedCourse }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div
      className="d-flex flex-column w-full mt-4 p-3 rounded"
      style={{ height: "auto", background: "white" }}
    >
      <div className="d-flex align-items-center w-full">
        <div className="" style={{ flex: 1, height: "auto" }}>
          <h3 className="m-0 headingH3">{chapterName}</h3>
          <p className="m-0">{modules + " "} modules</p>
        </div>
        <div className="" style={{ height: "auto", width: "50px" }}>
          {selectedCourse === id ? (
            <FaAngleUp
              style={{ height: "30px", width: "30px" }}
              onClick={() => {
                dispatch(selectCourse(""));
              }}
            />
          ) : (
            <FaAngleDown
              style={{ height: "30px", width: "30px" }}
              onClick={() => {
                dispatch(selectCourse(id));
              }}
            />
          )}
        </div>
      </div>
      {selectedCourse === id && (
        <div className="mt-4">
          {subModules &&
            subModules.map((subMod) => (
              <div className="d-flex align-items-center">
                <FaLongArrowAltRight
                  style={{ height: "30px", width: "30px", marginRight: "10px" }}
                />
                <div>
                  <h4>{subMod.moduleName}</h4>
                  <p>{subMod.totalQuestions} set questions</p>
                </div>
                <div className="ms-auto">
                  {/* {user && user?.admin === undefined && ( */}
                  {/* {isAdmin() && (
                    <button
                      className="btn btn-success mx-3"
                      onClick={() =>
                        navigate(`/admin/chapter-wise/${id}/${subMod._id}`)
                      }
                    >
                      Upload set questions
                    </button>
                  )} */}
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      // if(!hasPaid()){
                      navigate(`/chapter-wise/${id}/${subMod._id}`);
                      // }else{
                      // navigate('/buy-now');
                      // }
                    }}
                  >
                    Start
                  </button>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
const Home = () => {
  const course = useSelector((state) => state.course);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const { courses, selectedCourse } = course;

  const toggle = () => setOpen(!open);
  const navigatedTo = () => {
    navigate("/admin/chapter");
  };
  return (
    <React.Fragment>
      <section className="Chapter">
        <div className="Chapter_Heading">
          {/* <div className="Classes_Box_Flex"> */}
            <h1 className="Chapter_Heading_Heading">Subjects</h1>
            {/* {isAdmin() && (
              <button
                className="Classes_Box_Content_Single_Button"
                onClick={() => navigatedTo()}
              >
                Upload New Chapter
              </button>
            )} */}
       
        </div>
        <div className="Chapter_Content">
          {courses &&
            courses.map((dat) => {
              return (
                <>
                  <div
                    className="Chapter_Content_Single"
                    key={dat._id}
                    onClick={() => {
                      setOpen(true);
            
                      setData((prev) => (prev?._id === dat._id ? null : dat));

                    }}

                  >
                    <div
                    className="Chapter_Content_Single_Box"
                      style={{
                        background: data?._id === dat._id ? 'rgba(30, 144, 255, 1)' : 'rgba(255, 255, 255, 1)',
                        width: "100%",
                        height: '100%',
            


                      }}
                    >
                      <p className="Chapter_Content_Single_Paragraph"
                      style={{
                        color: data?._id === dat._id ? 'white' : 'black',
                      }} >
                      <span>
                      {dat.chapterName}

                      </span>
                        <span>
                         {data?._id === dat._id ? <FaAngleUp /> : <FaAngleDown />} 
                        </span>

                      </p>
                    </div>

                    <div
                      style={{
                        display: (open && dat._id === data?._id) ? "block" : "none",

                      }}
                      toggle={toggle}
                      centered
                      className="Modal"
                    >
                      <ModalBody className="Modal_Body">
                        {data !== null &&
                          data?.subModules.map((subMod) => {
                            return (
                              <div
                                className="Modal_Body_Single"
                                onClick={() => {
                                  navigate(
                                    `/chapter-wise/${data._id}/${subMod._id}`
                                  );
                                }}
                              >
                                <h4 className="Modal_Body_Single_Heading">
                                  {subMod.moduleName}
                                </h4>
                                <p className="Modal_Body_Single_Paragraph">
                                  {" "}
                                  {subMod.totalQuestions} set questions
                                </p>
                              </div>
                            );
                          })}
                      </ModalBody>
                    </div>
                  </div>


                </>
                // <Modules
                //   id={dat._id}
                //   chapterName={dat.chapterName}
                //   modules={dat.subModules.length}
                //   subModules={dat.subModules}
                //   selectedCourse={selectedCourse}
                // />
              );
            })}
            {
              !courses&&(
                <NotFound text="Sorry, we don’t have any resources available for this subject." />

              )
            }
        </div>
      </section>
      {/* <section className="pb-0 hero-section Chapter" id="hero">
        <Container>
          <Row className="justify-content-between">
            <Col sm={6} md={6} >
              <h4 className="text-black">Chapter</h4>
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center">
            <Col sm={6} md={6} className="align-items-center">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">NEC license exam</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Civil Engineering
                  </li>
                </ol>
              </nav>
            </Col>
            <Col sm={6} md={6} className="d-flex justify-content-end">
              {isAdmin() && (
                <button
                  className="btn btn-success"
                  onClick={() => navigatedTo()}
                >
                  Upload New Chapter
                </button>
              )}
            </Col>
          </Row>
          {!courses && (
            <div className="d-flex flex-column justify-content-center align-items-center h-full">
              <img
                src={notFound}
                alt="Not found "
                className="w-auto"
                style={{ height: "350px" }}
              />
              <h4 className="text-black mt-4 mb-4">
                Sorry, we could not find any chapters
              </h4>
            </div>
          )}
          {courses &&
            courses.map((dat) => {
              return (
                <Modules
                  id={dat._id}
                  chapterName={dat.chapterName}
                  modules={dat.subModules.length}
                  subModules={dat.subModules}
                  selectedCourse={selectedCourse}
                />
              );
            })}
        </Container>
      </section> */}
    </React.Fragment>
  );
};

export default Home;
