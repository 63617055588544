import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import NEC_LOGO from "../../assets/images/NEC_logo.gif";
import { useSelector, useDispatch } from "react-redux";
import { selectSetCourse } from "../../slices/SetCourse/reducer";
import { useNavigate } from "react-router-dom";
import notFound from "../../assets/images/sad-face.png";
import { isAdmin } from "../../utils/isAdmin";
import { hasPaid } from "../../utils/hasPaid";
import NotFound from "../../Components/NotFound";

const Course = ({ set }) => {
  const noOfQuestion = JSON.parse(set.questionSets).length;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setQuestion = () => {


    if (hasPaid()) {
      navigate(`/set-wise/${set._id}`);
    } else {
      navigate("/buy-now");
    }
  };

  return (
    <Col key={1} lg={4} md={6} sm={12}>
      <Card
        className="explore-box card-animate"
        style={{ border: "1px solid var(----gray, #E6E6E6)" }}
      >
        <div className="explore-place-bid-img">
          <input type="hidden" className="form-control" id="1" />
          <div className="d-none">undefined</div>
          <img
            // src={course.image}
            src={NEC_LOGO}
            alt=""
            className="card-img-top explore-img"
          />
          <div className="bg-overlay"></div>
          <div className="place-bid-btn">
            <button
              className="btn btn-primary TestSet_Box_Content_Single_Button"
              onClick={setQuestion}
            >
              <i className="ri-auction-fill align-bottom me-1"></i>Start Set
              Question
            </button>
          </div>
        </div>
        <CardBody>
          <h5 className="TestSet_Box_Content_Single_Heading">
            <Link
              to="/course"
              //   to={course.course_link}
              style={{ color: "#333" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              NEC license exam preparation
            </Link>
          </h5>
          <p className="TestSet_Box_Content_Single_paragraph">
            Civil Engineering
          </p>
          <p className="TestSet_Box_Content_Single_paragraph">
            {noOfQuestion} Questions
          </p>
        </CardBody>
      </Card>
    </Col>
  );
};
const Home = () => {
  const setCourse = useSelector((state) => state.set);
  const navigate = useNavigate();

  const { sets, setStatus } = setCourse;

  return (
    <React.Fragment>
      {/* <section className="TestSet">
        <div className="TestSet_Box">
          <div className="Classes_Box_Flex">
            <h1 className="TestSet_Box_Heading">Mock-Test Set</h1>
            {/* {isAdmin() && (
            <button
                className="Classes_Box_Content_Single_Button"
              onClick={() => navigatedTo()}
              >
                Upload New Set
              </button>
            )} */}
      {/* </div>
          <div className="TestSet_Box_Content">
            {(!sets || sets?.length === 0) && setStatus === "completed" && (
              <NotFound text="Sorry, we don’t have any mock tests available for this subject." />
            )}
            <Row className="m-0">
              {sets?.length > 0 && sets.map((set) => <Course set={set} />)}
            </Row>
          </div>
        </div> */}
      {/* </section> */}
      <section
        className="TestSet"
        id="hero"
        style={{ minHeight: "80vh" }}
      >
        <Container>
          <div className="Classes_Box_Flex">
            <h1 className="TestSet_Box_Heading">Mock-Test Set</h1>
            {/* {isAdmin() && (
            <button
                className="Classes_Box_Content_Single_Button"
                onClick={() => navigatedTo()}
              >
                Upload New Set
              </button>
            )} */}
          </div>
          {/* <Row className="justify-content-between">
            <Col sm={6} md={6} className="mb-3">
              <h4 className="card-title" style={{ marginTop: "30px" }}>
                Set Questions
              </h4>
            </Col>
            {isAdmin() && (
              <Col sm={6} md={6} className="d-flex justify-content-end mb-3">
                <button
                  className="btn btn-success"
                  // onClick={() => navigatedTo()}
                >
                  Upload New Set
                </button>
              </Col>
            )}
          </Row> */}
          <Row className="justify-content-center">
            {(!sets || sets?.length === 0) && setStatus === "completed" && (
              <NotFound text="Sorry, we don’t have any mock tests available for this subject." />
            )}
            {sets?.length > 0 && sets.map((set) => <Course set={set} />)}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Home;
