import React from "react";
import { Navigate } from "react-router-dom";
import Landing from "../pages/landing";
import ChapterWise from "../pages/chapterWise";
import ChapterCourse from "../pages/ChapterCourse";
import SetWise from "../pages/setWise";
import Blogs from "../pages/Blogs";
import Login from "../pages/Login/Login";
import Register from "../pages/Login/Register";
import SetCourse from "../pages/admin/SetCourse";
import OTP from "../pages/Login/OTP";
import ChapterSetCourse from "../pages/admin/ChapterSet";
import Chapter from "../pages/admin/chapter";
import AdminBlogs from "../pages/admin/blogs";
import SingleBlogs from "../pages/singleBlogs";
import ChapterWiseQuestions from "../pages/chapterWiserQuestions";
import Submission from "../pages/submission";
import BuyNow from "../pages/buy-now";
import PaymentSuccess from "../pages/payment-success";
import Courses from "../pages/Courses";
import SinglePageCourse from "../pages/Courses/SinglePageCourse";
import AdminCourses from "../pages/admin/Courses";
import Video from "../pages/video";
import Maintenance from "../pages/maintainence";
import Classes from "../pages/Classes";
import AdminClasses from "../pages/admin/Classes";
import AdminBillings from "../pages/admin/subscription";
import ResetPassword from "../pages/Login/ResetPassword";
import AdminUsers from "../pages/admin/users";
import LandingHome from "../pages/LandingHome";
import AdminBillingsDetails from "../pages/admin/users/BillingsDetails";
import AdminClassesDetails from "../pages/admin/Classes/AdminClassesDetails";
import AdminSetCourseDetails from "../pages/admin/SetCourse/SetCourseDetails";

const authProtectedRoutes = [
  { path: "/classes", component: <Classes /> },

  { path: "/set-wise/:id", component: <Landing /> },
  { path: "/chapter-wise/:id", component: <ChapterWiseQuestions /> },
  { path: "/chapter-wise/:chapterId/:subModuleId", component: <ChapterWise /> },
  { path: "/chapter-wise", component: <ChapterCourse /> },

  { path: "/set-wise", component: <SetWise /> },

  { path: "/submission", component: <Submission /> },

  { path: "/courses/:videoId", component: <Video /> },
  { path: "/courses", component: <Courses /> },

  { path: "/courses/:chapterId/:subModuleId", component: <SinglePageCourse /> },
  { path: "/maintenance", component: <Maintenance /> },
];

const adminProtectedRoutes = [
  { path: "/admin", component: <AdminUsers /> },
  { path: "/admin/set-course/create", component: <SetCourse /> },
  { path: "/admin/set-course", component: <AdminSetCourseDetails /> },
  { path: "/admin/classes/create", component: <AdminClasses /> },
  { path: "/admin/classes", component: <AdminClassesDetails /> },
  { path: "/admin/chapter", component: <Chapter /> },
  {
    path: "/admin/courses",
    component: <AdminCourses />,
  },
  {
    path: "/admin/chapter-wise",
    component: <ChapterSetCourse />,
  },
  { path: "/admin/users", component: <AdminUsers /> },
  { path: "/admin/billings/create", component: <AdminBillings /> },
  { path: "/admin/billings", component: <AdminBillingsDetails /> },
  { path: "/admin/blogs", component: <AdminBlogs /> },
];
const publicRoutes = [

  { path: "/home", component: <LandingHome /> },
  { path: "/payment-success", component: <PaymentSuccess /> },
  { path: "/login", component: <Login /> },
  { path: "/register", component: <Register /> },
  { path: "/check-otp", component: <OTP /> },
  { path: "/buy-now", component: <BuyNow /> },
  { path: "/reset-device", component: <ResetPassword /> },
  {path: '/blogs', component: <Blogs/>},
  {path: '/blogs/:id', component: <SingleBlogs/>},

  { path: "*", component: <Navigate to="/home" replace/> },

];

export { authProtectedRoutes, publicRoutes, adminProtectedRoutes };
