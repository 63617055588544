import React, { useState } from "react";
import ParticlesAuth from "./ParticlesAuth";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Alert,
  Input,
  Label,
} from "reactstrap";
import { Form, Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkOtpUser, resendOtp } from "../../slices/User/thunk";
import { setAuthorization } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import login from '../../assets/images/Login.svg';
const OTP = () => {
  const notify = () =>
    toast.success("Authentication successful", {
      style: {
        backgroundColor: "#28a745" /* Custom green color */,
        color: "white" /* Adjust text color for visibility */,
      },
      icon: false /* Optionally, you can remove the default icon */,
    });
  const dispatch = useDispatch();
  const mobileNumber = localStorage.getItem("mobileNumber");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [value, setValue] = useState("");

  const onSubmit = async () => {
    const checkUser = await dispatch(
      checkOtpUser({ mobileNumber: mobileNumber, userOtp: value })
    );
    if (checkUser.payload.status === "success") {
      localStorage.setItem("token", checkUser.payload.token);
      notify();
      localStorage.setItem("user", JSON.stringify(checkUser.payload.data.user));

      setAuthorization(
        checkUser.payload.token,
        checkUser.payload.data.user.deviceId
      );
      navigate("/classes");
    } else {
      setMessage(checkUser.payload.data);
    }
  };
  return (
    <React.Fragment>
      {/* <div className="auth-page-wrapper"> */}
      <ParticlesAuth>
        <section className="Register">
          <div className="Register_Left">
            <img src={login} alt="login"/>
          </div>
          <div className="Register_Right">
            <div className="Register_Right_Top">
              <h1 className="Register_Right_Heading">Check OTP</h1>
              {/* <p className="Register_Right_Paragraph">
                Fill out the information 
              </p> */}
            </div>

            <div className="Register_Right_Box">
              {message !== "" && message ? (
                <Alert color="danger"> {message} </Alert>
              ) : null}
              <div className="Register_Right_Box_InputBox">
                <Label
                  htmlFor="mobileNumber"
                  className="Register_Right_Box_InputBox_Heading"
                >
                  Mobile Number
                </Label>
                <Input
                  name="otp"
                  className="Register_Right_Box_InputBox_Input"
                  placeholder="Enter OTP"
                  type="number"
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  value={value}
                />
              </div>

              <div className="Register_Right_Box_Button">
                <button
                  className="Register_Right_Box_Button_Button"
                  onClick={onSubmit}
                >
                  Confirm
                </button>
              </div>
            </div>
            <p className="Register_Right_Login">
              Didn't receive a code ?{" "}
              <button
                onClick={() => {
                  dispatch(resendOtp({ mobileNumber: mobileNumber }));
                }}
                className="fw-semibold text-primary text-decoration-underline"
              >
                Resend
              </button>{" "}
            </p>
          </div>
        </section>
        {/* <div className="auth-page-content mt-lg-5 section pb-0 hero-section">
          <Container>
            <Row className="justify-content-center">
              <Row>
                <Col lg={12}>
                  <div className="text-center mt-sm-5 mb-4 text-white-50">
                    <p className="mt-3 fs-15 fw-medium">Learn with us</p>
                  </div>
                </Col>
              </Row>
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="mb-4">
                      <div className="avatar-lg mx-auto">
                        <div className="avatar-title bg-light text-primary display-5 rounded-circle">
                          <i className="ri-mail-line"></i>
                        </div>
                      </div>
                    </div>
                    {message !== "" && message ? (
                      <Alert color="danger"> {message} </Alert>
                    ) : null}

                    <div className="p-2 mt-4">
                      <div className="text-muted text-center mb-4 mx-lg-3">
                        <h4 className="">Verify Your mobile number</h4>
                        <p>
                          Please enter the 4 digit code sent to{" "}
                          <span className="fw-semibold">
                            {localStorage.getItem("mobileNumber")}
                          </span>
                        </p>
                      </div>

                      <form>
                        <Row>
                          <Col className="col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="digit1-input"
                                className="visually-hidden"
                              >
                                Digit 1
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg bg-light border-light text-center"
                                maxLength={1}
                                id="digit1-input"
                                onKeyUp={() => moveToNext(1)}
                              />
                            </div>
                          </Col>

                          <Col className="col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="digit2-input"
                                className="visually-hidden"
                              >
                                Digit 2
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg bg-light border-light text-center"
                                maxLength={1}
                                id="digit2-input"
                                onKeyUp={() => moveToNext(2)}
                              />
                            </div>
                          </Col>

                          <Col className="col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="digit3-input"
                                className="visually-hidden"
                              >
                                Digit 3
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg bg-light border-light text-center"
                                maxLength={1}
                                id="digit3-input"
                                onKeyUp={() => moveToNext(3)}
                              />
                            </div>
                          </Col>

                          <Col className="col-3">
                            <div className="mb-3">
                              <label
                                htmlFor="digit4-input"
                                className="visually-hidden"
                              >
                                Digit 4
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-lg bg-light border-light text-center"
                                maxLength={1}
                                id="digit4-input"
                                onKeyUp={() => moveToNext(4)}
                              />
                            </div>
                          </Col>
                        </Row>
                      </form>
                      <div className="mt-3">
                        <Button
                          color="success"
                          className="w-100"
                          onClick={onSubmit}
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Didn't receive a code ?{" "}
                    <button
                      onClick={() => {
                        dispatch(resendOtp({ mobileNumber: mobileNumber }));
                      }}
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      Resend
                    </button>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}
      </ParticlesAuth>
      {/* </div> */}
    </React.Fragment>
  );
};

export default OTP;
