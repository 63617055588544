import { createSlice } from "@reduxjs/toolkit";
import { deleteClassById, getBillings, getClasses, getCourse, getVideoCourseById, getVideoCourses } from "./thunk";

const initialState = {
  courses: [],
  selectedCourse: "",
  courseVideos: [],
  selectedCourseVideo: null,
  classes: [],
  classStatus: 'loading',
  billings:[],
};

export const coursesSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
  selectCourse: (state, action) => {
      state.selectedCourse = action.payload;
    },
    deleteClass: (state,action) =>{
      const itemId = action.payload;
      state.classes = state.classes.filter(item => item._id !== itemId);
    }
  },
  extraReducers: (builder) => {

    builder.addCase(getCourse.fulfilled, (state, action) => {
      state.courses = action.payload?.data?.data;
    });
    builder.addCase(getVideoCourses.fulfilled, (state, action) => {
      state.courseVideos = action.payload?.data
    });
    builder.addCase(getVideoCourseById.fulfilled, (state, action) =>{
      state.selectedCourseVideo = action.payload?.data
    });
    builder.addCase(getClasses.pending, (state, action) =>{
      state.classStatus = "loading"
    });
    builder.addCase(getClasses.fulfilled, (state,action)=>{
      state.classStatus = "completed"
      state.classes = action.payload.data
    }); 
     builder.addCase(getBillings.fulfilled, (state,action)=>{
      state.billings = action.payload.data
    });

  },
});

// Action creators are generated for each case reducer function
export const { selectCourse, deleteClass } = coursesSlice.actions;

export default coursesSlice.reducer;
