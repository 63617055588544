import React, {useEffect, useState} from "react";
import { Card, CardBody, Alert } from "reactstrap";
import { useDispatch } from "react-redux";
import { isChecked } from "../../utils/GetChecked";

// import { changeOption } from "../../slices/questionSlice";
// const c = option1, option2, option3, option4
const Question = ({
  id,
  question,
  options,
  selectedOption,
  selectedAnswer,
  isFinished,
  index,
  handleSelect,
  provideAnswer,
  answerGiven
}) => {
  const dispatch = useDispatch();
 
  const optionSelected = (number) =>{ 
    switch(number){
      case 0:
        return 'a';
      case 1: 
        return 'b';
      case 2:
        return 'c';
      case 3: 
        return 'd';
      default: 
        return null;
    }
   
  }

  return (
    <Card style={{ background: "light-grey", height: "auto" }} className="Question">
      <div className="d-flex align-items-center">
        <h3 className="Question_Heading">{index+ 1 +".  "} {question}</h3>
      </div>
      <CardBody>
        {options.map((opt, subIndex) => (
          <div className="d-flex align-items-center m-3">
            <input
              id={opt.option}
              type="checkbox"
              checked={Boolean(isChecked(subIndex, provideAnswer, index))}
              className="h-5 w-5"
              style={{ height: "24px", width: "24px", marginRight: "5px" }}
              onChange={() =>!Boolean(isChecked(subIndex, provideAnswer, index)) && handleSelect(subIndex, index, optionSelected(subIndex))}
            />
            <label
              htmlFor={opt.option}
              className="m-0 ml-3 Question_Paragraph"

              // onClick={() => handleSelect(subIndex, index)}
            >
              {opt.option}
            </label>
          </div>
        ))}
      </CardBody>
      <div>
      
      </div>
    </Card>
  );
};

export default Question;
