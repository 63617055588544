import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createClassesApi,
  createVideoCourseApi,
  deleteBillingsApi,
  deleteClassByIdApi,
  getAllCourses,
  getAllVideoCourseByIdApi,
  getAllVideoCoursesApi,
  getBillingsApi,
  getBillingsByIdApi,
  getClassesApi,
  postBillingsApi,
  updateBillingsApi,
  updateClassesApi,
} from "../../helpers/backend_helper";
export const getCourse = createAsyncThunk("course/getCourse", async () => {
  try {
    const response = await getAllCourses();

    return response;
  } catch (error) {
    return error;
  }
});

export const getVideoCourses = createAsyncThunk(
  "course/getVideoCourses",
  async (data) => {
    try {
      const response = await getAllVideoCoursesApi(data);

      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getVideoCourseById = createAsyncThunk(
  "course/getVideoCourseById",
  async (id) => {
    try {
      const response = await getAllVideoCourseByIdApi(id);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const createVideoCourse = createAsyncThunk(
  "course/createVideoCourse",
  async (data) => {
    try {
      const response = await createVideoCourseApi(data);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const createClasses = createAsyncThunk(
  "course/createClasses",
  async (data) => {
    try {
      const response = await createClassesApi(data);

      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getClasses = createAsyncThunk("course/getClasses", async () => {
  try {
    const response = await getClassesApi();

    return response;
  } catch (error) {
    return error;
  }
});

export const deleteClassById = createAsyncThunk(
  "course/deleteClassById",
  async (classId) => {
    try {
      const response = await deleteClassByIdApi(classId);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const updateClassById = createAsyncThunk(
  "course/updateClassById",
  async (data) => {
    try {
    // const response = await deleteClassByIdApi(classId);
      const response = await updateClassesApi(data._id, data);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getBillings = createAsyncThunk("course/billings", async () => {
  try {
    const response = await getBillingsApi();

    return response;
  } catch (error) {
    return error;
  }
});

export const updateBillingsById = createAsyncThunk(
  "course/updateBillings",
  async (data) => {
    try {
      const response = await updateBillingsApi(data._id, data);
      console.log(response);
      console.log('response is this and that ');
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getBillingById = createAsyncThunk(
  "course/billingsById",
  async (id) => {
    try {
      const response = await getBillingsByIdApi(id);

      return response;
    } catch (error) {
      return error;
    }
  }
);
export const postBilling = createAsyncThunk(
  "course/createBillings",
  async (data) => {
    try {
      const response = await postBillingsApi(data);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const deleteBilling = createAsyncThunk(
  "course/deleteBillings",
  async (id) => {
    try {
      const response = await deleteBillingsApi(id);
      return response;
    } catch (error) {
      return error;
    }
  }
);
