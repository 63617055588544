const config = {
    google: {
      API_KEY: "",
      CLIENT_ID: "",
      SECRET: "",
    },
    facebook: {
      APP_ID: "",
    },
    api: {
    //  secretKey: "test_secret_key_47aa4550df064b51ba129122a75b7f37",
      // secretKey:"live_secret_key_a0157e365d154dbda3710e7a835ad5d7",
      // KHALTI_URL: "https://khalti.com/api/v2/epayment/initiate/",
      // KHALTI_URL:  "https://a.khalti.com/api/v2/epayment/initiate/",
      API_URL: "https://api.sikaune.com/api/v1",
      // API_URL: "http://localhost:5000/api/v1",
    },
  };
  
  export default config;