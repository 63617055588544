import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar";
import { Col, Row, Card, CardHeader, CardBody } from "reactstrap";
import Dropzone from "react-dropzone";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addChapterSetCourse,
  addSetCourse,
} from "../../../slices/SetCourse/thunk";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getCourse } from "./../../../slices/Courses/thunk";

const ChapterSetCourse = () => {
  const notify = () =>
    toast.success("Set Added Successfully", {
      style: {
        backgroundColor: "#28a745" /* Custom green color */,
        color: "white" /* Adjust text color for visibility */,
      },
      icon: false /* Optionally, you can remove the default icon */,
    });
  const [selectedFiles, setselectedFiles] = useState([]);
  const courses = useSelector((state) => state.course.courses);

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [chapterOptions, setChapterOptions] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const dispatch = useDispatch();

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    if (selectedCourse && selectedChapter) {
      const chapterId = selectedCourse._id;
      const subModuleId = selectedChapter._id;
    } else {
      toast.error("Please select course and chapter");
      return;
    }
    if (selectedFiles.length === 0) {
      toast.error("Please select a file");
      return;
    }
    const chapterId = selectedCourse._id;
    const subModuleId = selectedChapter._id;
    formData.append("file", selectedFiles[0]);
    formData.append("chapterId", chapterId);
    formData.append("subModuleId", subModuleId);
    const response = await dispatch(addChapterSetCourse(formData));
    if (response.payload.status === "success") {
      setselectedFiles([]);
      notify();
    }
  };

  const handleCourseChange = (event) => {
    const courseId = event.target.value;
    const selectedCourseObject = courses.find(
      (course) => course._id === courseId
    );
    setSelectedCourse(selectedCourseObject);
    setSelectedChapter(null);
    setChapterOptions(selectedCourseObject.subModules);
  };

  const handleChapterChange = (event) => {
    const chapterId = event.target.value;
    const selectedChapterObject = chapterOptions.find(
      (chapter) => chapter._id === chapterId
    );
    setSelectedChapter(selectedChapterObject);
  };
  useEffect(() => {
    dispatch(getCourse());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="AdminDashboard_Main_Box">
        <h1 className="AdminDashboard_Main_Box_Heading">Upload Your File</h1>
        <div className="AdminDashboard_Main_Box_Box">
          <div className="AdminDashboard_Main_Box_Hello">
            <Dropzone
              className="AdminDashboard_Main_Box_DropZone"
              onDrop={(acceptedFiles) => {
                handleAcceptedFiles(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone dz-clickable">
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <div className="mb-3">
                      <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                    </div>
                    <h4 className="AdminDashboard_Main_Box_DropZone_Text">
                      Drop files here or click to upload.
                    </h4>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="list-unstyled mb-0" id="file-previews">
              {selectedFiles.map((f, i) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={f.name}
                            src={f.preview}
                          />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted font-weight-bold">
                            {f.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                );
              })}
            </div>
          </div>
          <div className="AdminDashboard_Main_Box_Right">
            <select
              className="AdminDashboard_Main_Box_Right_Select"
              value={selectedCourse ? selectedCourse._id : ""}
              onChange={handleCourseChange}
            >
              <option value="">Select Course</option>
              {courses &&
                courses.map((course) => (
                  <option key={course._id} value={course._id}>
                    {course.chapterName}
                  </option>
                ))}
            </select>

            <select
              className="AdminDashboard_Main_Box_Right_Select"
              value={selectedChapter ? selectedChapter._id : ""}
              onChange={handleChapterChange}
            >
              <option value="">Select Chapter</option>
              {chapterOptions.map((chapter) => (
                <option key={chapter._id} value={chapter._id}>
                  {chapter.moduleName}
                </option>
              ))}
            </select>
            <button
              className="AdminDashboard_Main_Box_Right_Button"
              onClick={handleSubmit}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChapterSetCourse;
