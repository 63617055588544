// AUTHENTICATION

//Chapters
export const POST_CHAPTER = "/chapters";
export const POST_USER = "/users";
export const POST_SET_COURSE = "/setCourse";
export const GET_SET_DATA = '/setCourse/setData';
export const POST_CHAPTER_SET = '/chapterSet';
export const POST_CHAPTER_MODULE = '/chapterSet/chapter'
export const POST_LOGIN = "/users/login";
export const POST_INITIATE_TRANSACTION = '/users/initiate-transaction';
export const POST_TRANSACTION = '/users/check-transaction'
export const POST_REGISTER = "/users/signup";
export const POST_LOGOUT = '/users/logout';
export const POST_SUBMISSION = '/setData'
export const POST_CLASSES = '/classes'
export const POST_CHECK_OTP = "/users/check-otp";
export const POST_RESENT_OTP = '/users/resend-otp'
export const GET_VIDEO_COURSES = '/courseVideo';
export const BLOGS = '/blogs'
export const BILLINGS = '/billings';

