import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import CustomTable1 from "../../../Components/Common/CustomTable1";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { createUsers, deleteUser, editUsers, getAllUsers } from "../../../slices/User/thunk";
import { deleteUserReducer } from "../../../slices/User/reducer";
import Navbar1 from "../../../Components/Navbar1";
import { toast } from "react-toastify";

const AdminUsers = () => {
  const userSet = useSelector((state) => state.users);
  const { users } = userSet;
  const dispatch = useDispatch();
  const [open, setOpen] = useState("");
  const [open1, setOpen1] = useState(false);
  const [data, setData] = useState();
  const [user, setUser] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    college: "",
    faculty: "",
    payment: false,
  });
  const onChangeData1 = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeData = (value) => {
    setData({ ...data, payment: value });
  };
  const toggle = () => setOpen("");
  const toggle1 = () => setOpen1(false);
  useEffect(() => {
    dispatch(getAllUsers());
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(editUsers(data));
    toast.success("User updated successfully", {autoClose:1000});
    toggle();
  };
  const handleSubmit1 = (e) => {
    e.preventDefault();

    dispatch(createUsers(user));
    // dispatch(editUsers(data));
  };
  const columns = [
    {
      header: "User Name",
      field: "name",
      width: "auto",
      truncate: true,
      className: "truncated-text",
    },
    {
      header: "Mobile Number",
      field: "mobileNumber",
      width: "auto",
      truncate: true,
      className: "truncated-text",
    },

    {
      header: "Payment Status",
      field: "exam_status",
      width: "25%",
      truncate: false,
      render: (item) => {
        return (
          <div className="d-flex align-items-center justify-content-between ">
            <Badge color={item.payment === true ? "success" : "primary"}>
              {item.payment === true ? "Paid" : "Pending"}
            </Badge>
          </div>
        );
      },
    },
    {
      header: "Action",
      field: "scoring_percentage",
      width: "12%",
      truncate: false,
      render: (item) => {
        return (
          <div>
            <Modal
              isOpen={open === item?._id}
              toggle={toggle}
              centered
              className="Modal"
            >
              <ModalBody className="Modal_Body">
                <h1 className="AddUser_Heading">Edit Student’s Information</h1>

                <div className="AddUser_Input">
                  <p className="AddUser_Input_Name">Full Name</p>
                  <input className="AddUser_Input_Input" value={data?.name} />
                </div>
                <div className="AddUser_Input">
                  <p className="AddUser_Input_Name">E-mail</p>
                  <input className="AddUser_Input_Input" value={data?.email} />
                </div>
                <div className="AddUser_Input">
                  <p className="AddUser_Input_Name">Mobile Number</p>
                  <input
                    className="AddUser_Input_Input"
                    value={data?.mobileNumber}
                  />
                </div>
                <div className="AddUser_Input">
                  <p className="AddUser_Input_Name">College</p>
                  <input
                    className="AddUser_Input_Input"
                    value={data?.college}
                  />
                </div>
                <div className="AddUser_Input">
                  <p className="AddUser_Input_Name">Faculty</p>
                  <input
                    className="AddUser_Input_Input"
                    value={data?.faculty}
                  />
                </div>
                <div className="AddUser_Input1">
                  <p className="AddUser_Input_Name">Active</p>
                  <input
                    type="checkbox"
                    className="AddUser_Input1_Input"
                    checked={data?.payment}
                    onClick={() => onChangeData(!data?.payment)}
                  />
                </div>
                <button className="AddUser_Button" onClick={handleSubmit}>
                  Edit Student
                </button>
              </ModalBody>
            </Modal>
            <Button
              className="btn-primary"
              onClick={() => {
                setOpen(item?._id);
                setData(item);
              }}
            >
              Edit
            </Button>
            <Button
              className="btn-danger"
              onClick={(e) => {
                // e.preventDefault();
                dispatch(deleteUserReducer(item?._id));
                dispatch(deleteUser(item?._id));
                toast.success("User deleted successfully");
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <React.Fragment>
      <div className="AdminUsers">
        <Modal isOpen={open1} toggle={toggle1} centered className="Modal">
          <ModalBody className="Modal_Body">
            <h1 className="AddUser_Heading">Add Student’s Information</h1>

            <div className="AddUser_Input">
              <p className="AddUser_Input_Name">Full Name</p>
              <input
                className="AddUser_Input_Input"
                name="name"
                onChange={onChangeData1}
                value={user?.name}
              />
            </div>
            <div className="AddUser_Input">
              <p className="AddUser_Input_Name">E-mail</p>
              <input
                className="AddUser_Input_Input"
                name="email"
                onChange={onChangeData1}
                value={user?.email}
              />
            </div>
            <div className="AddUser_Input">
              <p className="AddUser_Input_Name">Mobile Number</p>
              <input
                className="AddUser_Input_Input"
                name="mobileNumber"
                onChange={onChangeData1}
                value={user?.mobileNumber}
              />
            </div>
            <div className="AddUser_Input">
              <p className="AddUser_Input_Name">College</p>
              <input
                className="AddUser_Input_Input"
                name="college"
                onChange={onChangeData1}
                value={user?.college}
              />
            </div>
            <div className="AddUser_Input">
              <p className="AddUser_Input_Name">Faculty</p>
              <input
                className="AddUser_Input_Input"
                name="faculty"
                onChange={onChangeData1}
                value={user?.faculty}
              />
            </div>
            <div className="AddUser_Input1">
              <p className="AddUser_Input_Name">Active</p>
              <input
                type="checkbox"
                className="AddUser_Input1_Input"
                checked={user.payment}
                onClick={() => setUser({ ...user, payment: !user.payment })}
                // checked={data?.payment}
                // onClick={() => onChangeData(!data?.payment)}
              />
            </div>
            <button className="AddUser_Button" onClick={handleSubmit1}>
              Add Student
            </button>
          </ModalBody>
        </Modal>
        {/* <div className="AdminUsers_Input"> */}
        <input type="text" className="AdminUsers_Input" placeholder="Search" />
        {/* </div> */}
        <button
          className="AdminUsers_Button"
          onClick={() => {
            setOpen1(true);
          }}
        >
          Add Student
        </button>
      </div>
      <CustomTable1
        data={users || []}
        columns={columns}
        onClick={(item) => {
          // setShowEmployee(item);
          // setBooleanEmployee(true);
        }}
      />
    </React.Fragment>
  );
};

export default AdminUsers;