import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "./ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";
import login from '../../assets/images/Login.svg';
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser, resendOtp } from "../../slices/User/thunk";
import { toast } from "react-toastify";
const ResetPassword = (props) => {
  const notify = () =>
    toast.success("Reset Successful", {
      style: {
        backgroundColor: "#28a745" /* Custom green color */,
        color: "white" /* Adjust text color for visibility */,
      },
      icon: false /* Optionally, you can remove the default icon */,
    });
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      mobileNumber: "",
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string().required("Please Enter Your mobile number"),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      const hello = await dispatch(resendOtp(values));

      if (hello.payload.status === "success") {
        delete axios.defaults.headers.common["Authorization"];
        localStorage.setItem("mobileNumber", values.mobileNumber);
        notify();
        navigate("/check-otp");
      } else {
        setMessage(hello.payload.data);
      }

      setLoader(false);
    },
  });

  document.title = "SignIn | Sikaune";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <section className="Register">
          <div className="Register_Left">
          <img src={login} alt="Login"/>
          </div>
          <div className="Register_Right">
            <div className="Register_Right_Top">
              <h1 className="Register_Right_Heading">Forgot Device?</h1>
              <p className="Register_Right_Paragraph">
                Fill out the information to reset device
              </p>
            </div>

            <Form
              className="Register_Right_Box"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              action="#"
            >
              <div className="Register_Right_Box_InputBox">
                <Label
                  htmlFor="mobileNumber"
                  className="Register_Right_Box_InputBox_Heading"
                >
                  Mobile Number
                </Label>
                <Input
                  name="mobileNumber"
                  className="Register_Right_Box_InputBox_Input"
                  placeholder="Enter mobile number"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.mobileNumber || ""}
                  invalid={
                    validation.touched.mobileNumber &&
                    validation.errors.mobileNumber
                      ? true
                      : false
                  }
                />
                {validation.touched.mobileNumber &&
                validation.errors.mobileNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.mobileNumber}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="Register_Right_Box_Button">
                <button className="Register_Right_Box_Button_Button">
                  Reset Device
                </button>
              </div>
            </Form>
          </div>
        </section>
        {/* <div className="auth-page-content mt-lg-5 section pb-0 hero-section">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">Reset Device</p>
                    </div>
                    {message !== "" && message ? (
                      <Alert color="danger"> {message} </Alert>
                    ) : null}

                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                       

                        <div className="mt-4">
                          <Button
                            color="success"
                            disabled={loader && true}
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            {loader && (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            )}
                            Reset Device
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div> */}
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);
