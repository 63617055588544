import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LandingRight from '../../assets/images/LandingRight.svg';
const Landing = () => {
  const navigate = useNavigate();
  return (
    <section className="Landing">
      <div className="Landing_Content">
        <div className="Landing_Content_Left">
          <h1 className="Landing_Content_Left_Heading">
            Simplifying Education
          </h1>
          <p className="Landing_Content_Left_Paragraph">
            Sikaune is a group of engineers and educators developing online
            learning materials specializing in engineering fraternity. Sikaune
            provides skill development trainings for professional development.
          </p>
          <div className="Landing_Content_Left_Button">
            <Link to="/courses">
              <button className="Landing_Content_Left_Button_Primary">
                Explore Courses
              </button>
            </Link>
            {/* <button className="Landing_Content_Left_Button_Secondary">
              More Information
            </button> */}
          </div>
        </div>
        <div className="Landing_Content_Right">
          {/* */}
          {/* <img src={LandingRight} alt="Landing Right" className="Landing_Content_Right_Image"/> */}

          <iframe
            width="496"
            height="300"
            className="Landing_Content_Right_Video"
            src={`https://www.youtube.com/embed/5Pv3nve_jEQ`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
  
          {/* <div className="Landing_Content_Right_Box1">
            <div className="content-box-1">
              <img src="https://placehold.co/253x273" alt="" />
            </div>
            <div className="content-box-2">
              <img src="https://placehold.co/253x209" alt="" />
            </div>
          </div>
          <div className="Landing_Content_Right_Box2">
            <div className="content-box-3">
              <img src="https://placehold.co/254x147" alt="" />
            </div>
            <div className="content-box-4">
              <img src="https://placehold.co/253x338" alt="" />
            </div>

          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Landing;
