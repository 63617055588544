import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setAuthorization } from '../helpers/api_helper';

// Component for redirecting to 'buy-now' when a specific condition is met
const RedirectIfNoPayment = (props) => {
  // const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const deviceId = localStorage.getItem("user");
  const device = JSON.parse(deviceId);
  // const userProfile = JSON.parse(localStorage.getItem("user"));
  setAuthorization(token, device?.deviceId);
// useEffect(() => {
  //   // Check the condition and navigate to 'buy-now' if payment is false
  //   // if (userProfile?.payment === false && userProfile?.admin === false) {
  //     
  //     // navigate('/buy-now'); // Navigate to 'buy-now'
  //   // }
  // }, [navigate, userProfile]); // Dependencies for useEffect

  // Render the children if the condition doesn't trigger navigation
  return <>{props.children}</>; // Continue to render child components
};

export default RedirectIfNoPayment;
